import React, {useEffect, useState} from "react";
import axios from "axios";
import {ExportConfirmationDialog_EN, ExportConfirmationDialog_RO, LOCAL_URL} from "../constants/constants";
import {checkLogin} from "./Util";
import {useLanguage} from "../LanguageContext";


export default function ExportConfirmationDialog({setShowConf, id, href}) {

    const [testData, setTestData] = useState();
    const [readyToExportTestDataToCSV, setReadyToShowExportTestDataToCSV] = useState(false);

    let [redMessage, setRedMessage] = useState("");
    let [greenMessage, setGreenMessage] = useState("");
    let [showBadData, setShowBadData] = useState(false);
    let [showGreenData, setShowGreenData] = useState(false);

    const [messages, setMessages] = useState(ExportConfirmationDialog_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(ExportConfirmationDialog_EN);
        } else if (language === 'ro') {
            setMessages(ExportConfirmationDialog_RO);
        }
    }, [language])


    useEffect(() => {
        checkLogin();    //const loggedUserId = checkLogin();
    }, []);


    async function handleConfirmExport() {
        // console.log(`test ID: ${id}`);

        await axios.get(`${LOCAL_URL}/api/tests/${id}`)
            .then(response => {
                if (response.data.length === 0) {
                    setRedMessage(messages.TESTS_NOT_FOUND)
                    setShowBadData(true);
                } else {
                    const testDataTemp = response.data;
                    setTestData(testDataTemp);
                    setReadyToShowExportTestDataToCSV(true);
                }
            })
            .catch(function (error) {
                // console.error(messages.ERROR_FETCHING_DATA, error);
                setRedMessage(messages.ERROR_FETCHING_DATA + error.message);
                setShowBadData(true);
            });
    }


    useEffect(() => {
            if (readyToExportTestDataToCSV) {
                exportToCSV();

                // Wait for 3 seconds before changing the page
                const timeoutId = setTimeout(() => {
                    window.location.href = href;
                }, 2000);

                return () => {
                    // Clear the timeout when the component unmounts
                    clearTimeout(timeoutId);
                };
            }
        }
        , [readyToExportTestDataToCSV]
    );


    // eslint-disable-next-line react-hooks/exhaustive-deps
    function exportToCSV() {
        const csvData = convertToCSV();
        const blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement('a');
        const filename = "Test " + testData.testId;


        try {
            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                // Other browsers
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            setGreenMessage(messages.CSV_FILE_SAVED)
            setShowGreenData(true);
        } catch (error) {
            setRedMessage(messages.ERROR_IN_SAVING_DATA + error.message);
            setShowBadData(true);
        }
    }


    function convertToCSV() {

        for (let prop in testData) {
            if (testData.hasOwnProperty(prop)) {
            }
        }

        let csv = 'testName,numberOfAnswersForQuestion,maxNumberOfValidAnswers,admitQuestionsWithoutValidAnswer\n';
        csv += `${testData.testName},${testData.numberOfAnswersForQuestion},${testData.maxNumberOfValidAnswers},${testData.admitQuestionsWithoutValidAnswer}\n\n`;
        csv += `Question`
        for (let i = 0; i < testData.numberOfAnswersForQuestion; i++) {
            csv += ',Answer,Valid?';
        }
        csv += '\n';

        const processQuestion = (question) => {
            csv += `"${question.question}"`

            question.answers.forEach(answer => {
                csv += `,"${answer.answer}",${answer.rightAnswer}`;
            });
            csv += `\n`;
        };

        testData.questions.forEach(question => processQuestion(question));

        return csv;
    }


    function handleCancelExport() {
        setShowConf(false);
    }


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white border-separate border-green-500 border-4 shadow-md rounded-lg p-4">
                <p className="font-bold">{messages.ARE_YOU_SURE}</p>
                <div className="mt-4 flex justify-center">
                    <button
                        className="bg-green-500 text-white px-4 py-2 rounded-lg mr-2"
                        onClick={handleConfirmExport}>
                        Confirm
                    </button>
                    <button
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
                        onClick={handleCancelExport}>
                        Cancel
                    </button>
                </div>

                <div className="mt-4">
                    {showBadData && (
                        <div className="bg-red-50 border-separate border-red-500 border-4 shadow-md rounded-lg p-4">
                            {redMessage}
                        </div>
                    )}

                    {showGreenData && (
                        <div
                            className="bg-green-50 text-center font-bold border-separate border-green-500 border-4 shadow-md rounded-lg p-4">
                            {greenMessage}
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}
