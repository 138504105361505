import React, {useEffect, useState} from "react";

import {
    ACTION_BUTTON,
    LOCAL_URL,
    QUESTION_MAX_NO_OF_ANSWERS,
    QUESTION_MIN_NO_OF_ANSWERS,
    QUESTION_MIN_NO_OF_VALID_ANSWERS,
    ShowImportTestPage_EN,
    ShowImportTestPage_RO,
    SINGLE_TEST_BODY1,
    SINGLE_TEST_BODY2,
    SINGLE_TEST_HEAD1,
    SINGLE_TEST_HEAD2,
    TEST_CONTENT_ANSWER_BODY1,
    TEST_CONTENT_ANSWER_BODY2,
    TEST_CONTENT_ANSWER_HEAD1,
    TEST_CONTENT_ANSWER_HEAD2,
    TEST_CONTENT_QUESTION_BODY1,
    TEST_CONTENT_QUESTION_BODY2,
    TEST_CONTENT_QUESTION_HEAD1,
    TEST_CONTENT_QUESTION_HEAD2,
    TEST_MAX_NO_OF_QUESTIONS,
    TEST_MIN_NO_OF_QUESTIONS,
    TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS
} from "../constants/constants";
import {Button} from "@material-tailwind/react";
import MessageRed from "./MessageRed";
import MessageGreen from "./MessageGreen";
import axios from "axios";
import {checkLogin} from "./Util";
import {useLanguage} from "../LanguageContext";


export default function ShowImportTestPage() {

    const [testData, setTestData] = useState({});

    const fragment = [];
    let data = {};
    let savedNewTest;
    let savedNewQuestion;

    let [redMessage, setRedMessage] = useState("")
    let [showBadData, setShowBadData] = useState(false);

    const [renderComponent, setRenderComponent] = useState(false);

    const userId = checkLogin();

    const [messages, setMessages] = useState(ShowImportTestPage_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(ShowImportTestPage_EN);
        } else if (language === 'ro') {
            setMessages(ShowImportTestPage_RO);
        }
    }, [language])


    function checkMinNoOfQuestions() {
        if (testData.numberOfQuestions >= TEST_MIN_NO_OF_QUESTIONS) {
            return true;
        } else {
            setRedMessage(messages.NO_OF_QUESTIONS_NOT_SMALLER_THAN + TEST_MIN_NO_OF_QUESTIONS);
            return false;
        }
    }


    function checkMaxNoOfQuestions() {
        if (testData.numberOfQuestions <= TEST_MAX_NO_OF_QUESTIONS) {
            return true;
        } else {
            setRedMessage(messages.NO_OF_QUESTIONS_NOT_GREATER_THAN + TEST_MAX_NO_OF_QUESTIONS);
            return false;
        }
    }


    function checkMinNoOfAnswers() {
        if (testData.numberOfAnswersForQuestion >= QUESTION_MIN_NO_OF_ANSWERS) {
            return true;
        } else {
            setRedMessage(messages.N0_OF_ANSWERS_NOT_SMALLER_THAN + TEST_MIN_NO_OF_QUESTIONS);
            return false;
        }
    }


    function checkMaxNoOfAnswers() {
        if (testData.numberOfAnswersForQuestion <= QUESTION_MAX_NO_OF_ANSWERS) {
            return true;
        } else {
            setRedMessage(messages.N0_OF_ANSWERS_NOT_GREATER_THAN + QUESTION_MAX_NO_OF_ANSWERS);
            return false;
        }
    }


    function checkNumberOfValidAnswers() {
        if (testData.admitQuestionsWithoutValidAnswer === false) {
            if (testData.maxNumberOfValidAnswers >= QUESTION_MIN_NO_OF_VALID_ANSWERS
                && checkMaxNumberOfValidAnswers()) {
                return true;
            } else {
                setRedMessage(messages.NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN + QUESTION_MIN_NO_OF_VALID_ANSWERS);
                return false;
            }
        } else if (testData.admitQuestionsWithoutValidAnswer === true) {
            if (testData.maxNumberOfValidAnswers >= TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS
                && checkMaxNumberOfValidAnswers()) {
                return true;
            } else {
                setRedMessage(messages.NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN + TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS);
                return false;
            }
        } else {
            setRedMessage(messages.UNABLE_TO_READ_TEST_DATA)
        }
    }


    function checkMaxNumberOfValidAnswers() {
        if (testData.maxNumberOfValidAnswers <= testData.numberOfAnswersForQuestion) {
            return true;
        } else {
            setRedMessage(messages.NO_OF_VALID_ANSWERS_NOT_GREATER_THAN + testData.numberOfAnswersForQuestion);
            return false;
        }
    }


    async function checkForTestNameHasNoDuplicates() {

        await axios.get(`${LOCAL_URL}/api/users/user-id/${userId}`)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                setRedMessage(messages.ERROR_FETCHING_DATA + error.message);
                setShowBadData(true);
                return false;
            });

        for (const test of data?.setOfTests) {
            const testName = test.testName;

            if (testData.testName === testName) {
                setRedMessage(messages.TEST_NAME_ALREADY_USED);
                return false
            }
        }
        return true;
    }


    function checkAllAnswersAndQuestion() {

        let answerCounter = 0;
        let rightAnswerCount = 0;
        let questionCounter = 0;

        for (let i = 0; i < testData.numberOfQuestions; i++) {
            if (testData.questions[i].question !== "" && testData.questions[i].question !== undefined) {
                questionCounter++;
            }
            rightAnswerCount = 0;
            for (let j = 0; j < testData.numberOfAnswersForQuestion; j++) {

                if (testData.questions[i].answers[j].answer !== ""
                    && testData.questions[i].answers[j].answer !== undefined) {
                    answerCounter++;
                }

                if (testData.questions[i].answers[j].rightAnswer === 'true'
                    || testData.questions[i].answers[j].rightAnswer === 'TRUE'
                    || testData.questions[i].answers[j].rightAnswer === true) {

                    rightAnswerCount++;
                }
            }

            if (rightAnswerCount > testData.maxNumberOfValidAnswers) {
                setRedMessage(messages.TO_MANY_VALID_ANSWERS_FOR_QUESTION + i);
                return false;
            }

            if (testData.admitQuestionsWithoutValidAnswer === "false"
                || testData.admitQuestionsWithoutValidAnswer === "False"
                || testData.admitQuestionsWithoutValidAnswer === false) {
                if (rightAnswerCount === 0) {
                    setRedMessage(messages.NO_OF_RIGHT_ANSWERS_BETWEEN_1_AND + testData.maxNumberOfValidAnswers + messages.FOR_QUESTION + i + 1);
                    return false;
                }
            }
        }

        if (questionCounter < testData.numberOfQuestions
            || answerCounter < testData.numberOfQuestions * testData.numberOfAnswersForQuestion) {

            setRedMessage( messages.INPUT_ALL_DATA);
            return false;
        }
        return true;
    }


    function checkDataTestHeader() {

        if (testData.testName === "") {
            setRedMessage(messages.THE_TEST_NAME_IS_MISSING)
            return false;
        } else if (testData.numberOfQuestions < TEST_MIN_NO_OF_QUESTIONS
            || testData.numberOfQuestions > TEST_MAX_NO_OF_QUESTIONS) {
            setRedMessage(messages.NO_OF_Q_NOT_BTW + TEST_MIN_NO_OF_QUESTIONS + messages.AND + TEST_MAX_NO_OF_QUESTIONS);
            return false;
        } else if (testData.numberOfAnswersForQuestion < QUESTION_MIN_NO_OF_ANSWERS
            || testData.numberOfAnswersForQuestion > QUESTION_MAX_NO_OF_ANSWERS) {
            setRedMessage(messages.NO_OF_A_NOT_BTW + QUESTION_MIN_NO_OF_ANSWERS + messages.AND + QUESTION_MAX_NO_OF_ANSWERS);
            return false;
        } else if (testData.admitQuestionsWithoutValidAnswer === true) {
            if (testData.maxNumberOfValidAnswers < 0 || testData.maxNumberOfValidAnswers > testData.numberOfAnswersForQuestion) {
                setRedMessage(messages.NO_OF_VALID_A_NOT_BTW + 0 + messages.AND + testData.numberOfAnswersForQuestion)
                return false;
            }
        } else if (testData.admitQuestionsWithoutValidAnswer === false) {
            if (testData.maxNumberOfValidAnswers < 1 || testData.maxNumberOfValidAnswers > testData.numberOfAnswersForQuestion) {
                setRedMessage(messages.NO_OF_VALID_A_NOT_BTW + 1 + messages.AND + testData.numberOfAnswersForQuestion)
                return false;
            }
        }
        return true;
    }


    async function checkTestBeforeSave() {
        return (
            checkDataTestHeader()
            && checkMinNoOfQuestions()
            && checkMaxNoOfQuestions()
            && checkMinNoOfAnswers()
            && checkMaxNoOfAnswers()
            && checkNumberOfValidAnswers()
            && checkAllAnswersAndQuestion()
            && await checkForTestNameHasNoDuplicates());
    }


    async function saveImportedCSVTest(e) {
        e.preventDefault();
        if (
            await checkTestBeforeSave()
        ) {
            console.log("add imported csv test");
            setShowBadData(false);
            try {
                const responseTest = await axios.post(`${LOCAL_URL}/api/tests/${userId}`, {
                    testName: testData.testName,
                    numberOfQuestions: testData.numberOfQuestions,
                    numberOfAnswersForQuestion: testData.numberOfAnswersForQuestion,
                    maxNumberOfValidAnswers: testData.maxNumberOfValidAnswers,
                    admitQuestionsWithoutValidAnswer: testData.admitQuestionsWithoutValidAnswer,
                });

                savedNewTest = responseTest.data;

            } catch (error) {
                console.error(error);
                setRedMessage(messages.ERROR_WRITING_TEST_TO_DB + error.message);
                setShowBadData(true);

            }


            for (let i = 0; i < savedNewTest.numberOfQuestions; i++) {
                if (testData.questions[i]) {
                    try {
                        const responseQuestion =
                            await axios.put(`${LOCAL_URL}/api/questions/${savedNewTest.testId}`, {
                                question: testData.questions[i].question,
                            });

                        savedNewQuestion = responseQuestion.data;

                    } catch (error) {

                        console.error(error);
                        setRedMessage(messages.ERROR_WRITING_QUESTION_TO_DB + error.message);
                        setShowBadData(true);

                        //TODO: it is ok?
                        await axios.delete(`${LOCAL_URL}/api/tests/${savedNewTest.testId}`)
                            .then(function (response) {
                                // Handle success, e.g., update UI or perform other tasks
                                console.log(messages.DELETED_SUCCESSFULLY, response);
                            })
                            .catch(function (error) {
                                // Handle error, e.g., show an error message to the user
                                console.error(messages.ERROR_DELETING_RESOURCE, error);
                                setRedMessage(messages.ERROR_DELETING_RESOURCE + error.message);
                                setShowBadData(true);
                            });
                    }
                }

                for (let j = 0; j < savedNewTest.numberOfAnswersForQuestion; j++) {
                    if (testData.questions[i].answers[j]) {
                        try {
                            await axios.put(`${LOCAL_URL}/api/answers/${savedNewQuestion?.questionId}`, {
                                answer: testData.questions[i].answers[j].answer,
                                rightAnswer: testData.questions[i].answers[j].rightAnswer
                            });
                        } catch (error) {
                            console.error(error);
                            setRedMessage(messages.ERROR_WRITING_ANSWER_TO_DB + error.message);
                            setShowBadData(true);


                            //TODO: it is ok?
                            await axios.delete(`${LOCAL_URL}/api/tests/${savedNewTest.testId}`)
                                .then(function (response) {
                                    // Handle success, e.g., update UI or perform other tasks
                                    console.log(messages.DELETED_SUCCESSFULLY, response);
                                })
                                .catch(function (error) {
                                    // Handle error, e.g., show an error message to the user
                                    console.error(messages.ERROR_DELETING_RESOURCE, error);
                                    setRedMessage(messages.ERROR_DELETING_RESOURCE + error.message);
                                    setShowBadData(true);
                                });
                        }
                    }
                }
            }
            window.location.href = `/tests/${savedNewTest.testId}`;
        } else {
            console.log(messages.FAIL_TO_ADD_IMPORTED_CSV_TEST);
            setRedMessage(messages.FAIL_TO_ADD_IMPORTED_CSV_TEST)
            setShowBadData(true);
        }
    }


    useEffect(() => {
        const importTestData = JSON.parse(sessionStorage.getItem("testData"));
        setTestData(importTestData);
        setRenderComponent(true);
    }, []);


    const dataChecked2 = () => {
        return (testData.numberOfQuestions >= TEST_MIN_NO_OF_QUESTIONS)
            && (testData.testName !== '')
            && (testData.numberOfQuestions <= TEST_MAX_NO_OF_QUESTIONS)
            && (testData.numberOfAnswersForQuestion >= QUESTION_MIN_NO_OF_ANSWERS)
            && (testData.numberOfAnswersForQuestion <= QUESTION_MAX_NO_OF_ANSWERS)
            && (testData.maxNumberOfValidAnswers >= TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS)
            && (testData.maxNumberOfValidAnswers <= testData.numberOfAnswersForQuestion);
    }


    function writeTestStructure() {

        for (let i = 0; i < testData.numberOfQuestions; i++) {
            const questionRows = [];
            const answerRows = [];
            questionRows.push(
                <tr id="question" className="w-full border-collapse" key={i + 100}>
                    <td className={`${TEST_CONTENT_QUESTION_BODY1}`}>{i + 1}</td>
                    <td className={`${TEST_CONTENT_QUESTION_BODY2}`}>
                        <input
                            type="text"
                            className="form-control w-full"
                            defaultValue={testData.questions[i].question === undefined ? "" : testData.questions[i].question}

                            onChange={(e) => {
                                const updatedQuestions = [...testData.questions];
                                updatedQuestions[i].question = e.target.value;
                                const updatedTestData = {...testData, questions: updatedQuestions};
                                setTestData(updatedTestData);
                            }}
                        />
                    </td>
                </tr>
            );

            for (let j = 0; j < testData.numberOfAnswersForQuestion; j++) {
                answerRows.push(
                    <tr id="answer" className="w-full border-collapse" key={j + 200}>
                        <td className={`${TEST_CONTENT_ANSWER_BODY1}`}>{j + 1}</td>
                        <td className={`${TEST_CONTENT_ANSWER_BODY2}`}>
                            <input
                                type="text"
                                className="form-control w-full"
                                defaultValue={testData.questions[i].answers[j].answer === undefined ? "" : testData.questions[i].answers[j].answer}
                                onChange={(e) => {
                                    const updatedAnswers = [...testData.questions[i].answers];
                                    updatedAnswers[j].answer = e.target.value;
                                    const updatedQuestions = [...testData.questions];
                                    updatedQuestions[i].answers = updatedAnswers;
                                    const updatedTestData = {...testData, questions: updatedQuestions};
                                    setTestData(updatedTestData);
                                }}
                            />
                        </td>

                        <td className={`${TEST_CONTENT_ANSWER_BODY1}`}>
                            <div className="form-control flex items-center justify-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox w-full w-4 h-4 "
                                    checked={(testData.questions[i].answers[j].rightAnswer === "true"
                                        || testData.questions[i].answers[j].rightAnswer === "TRUE"
                                        || testData.questions[i].answers[j].rightAnswer === true)}
                                    onChange={(e) => {
                                        const updatedAnswers = [...testData.questions[i].answers];
                                        updatedAnswers[j].rightAnswer = e.target.checked;
                                        console.log("new value: " + e.target.checked);
                                        const updatedQuestions = [...testData.questions];
                                        updatedQuestions[i].answers = updatedAnswers;
                                        const updatedTestData = {...testData, questions: updatedQuestions};
                                        setTestData(updatedTestData);
                                    }}
                                />
                            </div>
                        </td>
                    </tr>
                );
            }


            fragment.push(
                <React.Fragment>
                    <div
                        className="h-full w-full overflow-y-auto justify-center items-center mt-2
                                        border-separate border-blue-400 border-2 rounded-lg">
                        <table
                            className="w-full table-auto mx-auto max-w-screen-3xl px-2 py-2
                                            rounded-lg bg-green-50">

                            <thead>
                            <tr className="w-full border-collapse">
                                <th className={`${TEST_CONTENT_QUESTION_HEAD1}`}>{messages.QUESTION_NO}</th>
                                <th className={`${TEST_CONTENT_QUESTION_HEAD2}`}>{messages.QUESTION}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {questionRows}
                            </tbody>
                        </table>

                        <table
                            className="w-full table-auto mx-auto max-w-screen-32xl px-2 py-2
                                                rounded-lg bg-yellow-50">

                            <thead>
                            <tr className="w-full border-collapse">
                                <th className={`${TEST_CONTENT_ANSWER_HEAD1}`}>{messages.ANSWER_NO}</th>
                                <th className={`${TEST_CONTENT_ANSWER_HEAD2}`}>{messages.ANSWER}</th>
                                <th className={`${TEST_CONTENT_ANSWER_HEAD1}`}>{messages.RIGHT_ANSWER}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {answerRows}
                            </tbody>

                        </table>

                    </div>
                </React.Fragment>
            );
        }
        return fragment;
    }


    const populateInner = () => {
        return renderComponent && writeTestStructure().map((item, index) =>
            <div key={index}>
                {item}
            </div>
        )
    }

    return (
        <div className="mt-16">
            <form className="mx-auto my-auto col-md-6 ">
                <div className="flex flex-col">
                    <div className="space-y-1 h-screen">
                        <div className=" mt-2 z-40 w-full">
                            <div className="overflow-hidden">
                                <table className="table-auto mx-auto max-w-screen-3xl px-2 py-2
                                            border-separate border-blue-400 border-4 rounded-lg
                                            bg-cyan-50 "
                                >

                                    <thead>
                                    <tr className="w-full border-collapse">
                                        <th className={`${SINGLE_TEST_HEAD1}`}>
                                            <strong>{messages.IMPORT_TEST}</strong><br/>{messages.TEST_NAME}
                                        </th>
                                        <th className={`${SINGLE_TEST_HEAD2}`}>{messages.NO_OF_QUESTIONS}
                                        </th>
                                        <th className={`${SINGLE_TEST_HEAD2}`}>{messages.NO_OF_ANSWERS_FOR_QUESTION}
                                        </th>
                                        <th className={`${SINGLE_TEST_HEAD2}`}>{messages.MAX_NO_VALID_ANSWERS}
                                        </th>
                                        <th className={`${SINGLE_TEST_HEAD2}`}>{messages.ADMIT_Q_WITHOUT_VALID_ANSWER}
                                        </th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr className="w-full border-collapse">
                                        <td className={`${SINGLE_TEST_BODY1}`}>
                                            <input
                                                type="text"
                                                value={testData.testName}
                                                onChange={event => setTestData({
                                                    ...testData,
                                                    testName: event.target.value
                                                })}
                                                className="form-control w-full"/>
                                        </td>
                                        <td className={`${SINGLE_TEST_BODY2}`}>
                                            {testData.numberOfQuestions}
                                        </td>
                                        <td className={`${SINGLE_TEST_BODY2}`}>
                                            {testData.numberOfAnswersForQuestion}
                                        </td>
                                        <td className={`${SINGLE_TEST_BODY2}`}>
                                            {testData.maxNumberOfValidAnswers}
                                        </td>
                                        <td className={`${SINGLE_TEST_BODY2}`}>
                                            <div
                                                className="form-control flex items-center justify-center">
                                                {testData.admitQuestionsWithoutValidAnswer ? messages.YES : messages.NO}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="relative h-screen overflow-hidden">
                            <div
                                className="absolute inset-0 overflow-y-auto mb-2">
                                <div>
                                    {
                                        (
                                            dataChecked2()
                                        ) ? (
                                            <div className=" w-full h-full table-auto mx-auto max-w-screen-3xl px-2 pb-2
                                                border-separate border-blue-400 border-4 rounded-lg
                                                bg-cyan-50">
                                                {
                                                    populateInner()
                                                }
                                            </div>
                                        ) : (
                                            <div
                                                className=" flex items-center justify-center text-center text-xl
                                                text-red-600 bg-red-50 h-full rounded-md mx-auto w-full font-bold">
                                                {redMessage = "The CSV file import resulted in invalid data or missing test name."}
                                                {showBadData = true}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>


                        <div className="mt-4">
                            {showBadData ? (
                                <div>
                                    <MessageRed message={redMessage}/>
                                </div>
                            ) : (
                                <div>
                                    {dataChecked2() && (
                                        <div>
                                            <MessageGreen message={messages.CHECK_IMPORTED_TEST}/>
                                        </div>
                                    )
                                    }
                                </div>
                            )}

                            <Button
                                // variant="gradient"
                                size="lg" fullWidth
                                // className="basis-1/4 mt-4 "
                                className={`${ACTION_BUTTON}`}
                                onClick={saveImportedCSVTest}
                            >
                                {messages.SAVE_IMPORTED_TEST}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}


