import React from "react";

export default function  PrivacyPolicyPage() {
    return (
        <div className="flex items-center justify-center text-center text-3xl text-red-600 bg-red-50 h-full
            rounded-md mx-auto w-screen mt-20">
            <p className="font-bold">
                Privacy Policy Page
            </p>
        </div>
    )
}
