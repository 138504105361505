import React, {useEffect, useState} from "react";
import {LOCAL_URL, SignUpPage_EN, SignUpPage_RO} from "../constants/constants";
import axios from "axios";
import MessageRed from "../components/MessageRed";
import MessageGreen from "../components/MessageGreen";
import {useLanguage} from "../LanguageContext";
import {checkLogin} from "../components/Util";

export default function SignUpPage() {

    const [registrationData, setRegistrationData] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        confirm_password: ''
    });

    const checkEmailUrl = `${LOCAL_URL}/api/auth/check-email`;

    const registerUrl = `${LOCAL_URL}/api/auth/register`;

    const [showBadData, setShowBadData] = useState(false);

    const [redMessage, setRedMessage] = useState("");

    const [greenMessage, setGreenMessage] = useState("");

    const [messages, setMessages] = useState(SignUpPage_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(SignUpPage_EN);
        } else if (language === 'ro') {
            setMessages(SignUpPage_RO);
        }
    }, [language])

    // useEffect(() => {
    //     checkLogin();
    // }, []);


    const handleSubmitClick = async () => {

        if (!checkPassword()) {
            return false;
        }

        if (!await checkEmail()) {
            return false;
        }

        try {
            await axios.post(registerUrl, {
                name: registrationData.name,
                surname: registrationData.surname,
                email: registrationData.email,
                password: registrationData.password
            });
        } catch (e) {
            console.log(messages.ERROR_SAVING_USER_DATA + e);
            setRedMessage(messages.ERROR_SAVING_USER_DATA + e);
            setShowBadData(true);
        }
        sessionStorage.clear();
        window.location.href = "/auth/login";
    }

    const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const checkEmail = async () => {

        if (!isEmail(registrationData.email)) {
            console.log(messages.WRONG_EMAIL_FORMAT);
            setShowBadData(true);
            setRedMessage(messages.WRONG_EMAIL_FORMAT);
            return false;
        }

        const emailExists = await checkForNoUserEmailDuplicates(registrationData.email);

        if (emailExists === false) {
            setGreenMessage(messages.WELCOME);
            return true;

        } else if (emailExists === true) {
            setShowBadData(true);
            setRedMessage(messages.ALREADY_REGISTERED)
            return false;
        } else {
            alert(messages.NO_EMAIL)
            return false;
        }
    }


    const checkPassword = () => {

        if (registrationData.password.length < 5) {
            setShowBadData(true);
            setRedMessage(messages.PASSWORD_SMALLER_THAN);
            return false;

        } else if (registrationData.password !== registrationData.confirm_password) {
            setShowBadData(true)
            setRedMessage(messages.PASSWORD_DON_T_MATCH);
            return false;

        } else {
            setShowBadData(false);
            setGreenMessage(messages.PASSWORD_MATCH);
            return true;
        }
    }

    const checkForNoUserEmailDuplicates = async (email) => {
        try {
            const response = await axios.get(checkEmailUrl, {params: {email: email}});
            return response.data;
        } catch (error) {
            setRedMessage(messages.ERROR_RETRIEVING_DATA + error.message);
            setShowBadData(true);
        }
    };


    return (
        // <!-- component -->
        <div className="sign-up-page">

            <div className="bg-blue-gray-50 min-h-screen flex flex-col">
                <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                    <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">

                        <h1 className="mb-8 font-bold text-3xl text-center text-indigo-800">
                            {messages.WELCOME}
                        </h1>

                        {/*<form>*/}
                        <input
                            type="text"
                            className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4"
                            name="name"
                            placeholder={messages.NAME}
                            onChange={(e) => setRegistrationData(
                                {...registrationData, name: e.target.value}
                            )}
                        />

                        <input
                            type="text"
                            className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4"
                            name="surname"
                            placeholder={messages.SURNAME}
                            onChange={(e) => setRegistrationData(
                                {...registrationData, surname: e.target.value}
                            )}
                        />

                        <input
                            type="text"
                            className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4"
                            name="email"
                            placeholder={messages.EMAIL}
                            onChange={(e) => setRegistrationData(
                                {...registrationData, email: e.target.value}
                            )}
                        />

                        <input
                            type="password"
                            className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4"
                            name="password"
                            placeholder={messages.PASSWORD}
                            value={registrationData.password}
                            onChange={(e) => setRegistrationData(
                                {...registrationData, password: e.target.value}
                            )}
                        />
                        <input
                            type="password"
                            className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4"
                            name="confirm_password"
                            placeholder={messages.CONFIRM_PASSWORD}
                            onChange={(e) => setRegistrationData({
                                ...registrationData,
                                confirm_password: e.target.value
                            })}
                        />

                        <div className="my-2">
                            {showBadData ? (
                                <div>
                                    <MessageRed message={redMessage}/>
                                </div>
                            ) : (
                                <div>
                                    <MessageGreen message={greenMessage}/>
                                </div>
                            )}
                        </div>

                        <button
                            type="submit"
                            className="w-full text-center py-3 rounded-lg bg-green-500 text-white hover:bg-green-700 transition-all duration-500 focus:outline-none my-1"
                            onClick={() => handleSubmitClick()}
                        >
                            {messages.CREATE_ACCOUNT}
                        </button>
                        {/*</form>*/}

                        <div className="text-center text-sm text-grey-dark mt-4">
                            {messages.BY_SIGNING_UP}&nbsp;
                            <a className="underline border-b border-grey-dark font-bold text-blue-700"
                               href="/auth/terms-of-service">
                                {messages.TERMS_OF_SERVICE}
                            </a> {messages.AND}&nbsp;
                            <a className="underline border-b border-grey-dark font-bold text-blue-700"
                               href="/auth/privacy-policy">
                                {messages.PRIVACY_POLICY}
                            </a>
                        </div>
                    </div>

                    <div className="text-grey-dark mt-6">
                        {messages.ALREADY_HAVE_AN_ACCOUNT}
                        <a className="underline border-b border-blue font-bold text-blue-700"
                           href="/auth/login/">
                            &nbsp;{messages.LOG_IN}
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )


}
