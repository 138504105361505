import React, {useEffect, useState} from "react";

import {
    TEST_CONTENT_QUESTION_HEAD1,
    TEST_CONTENT_QUESTION_HEAD2,
    TEST_CONTENT_QUESTION_BODY1,
    TEST_CONTENT_QUESTION_BODY2,
    TEST_CONTENT_ANSWER_HEAD1,
    TEST_CONTENT_ANSWER_HEAD2,
    TEST_CONTENT_ANSWER_BODY1,
    TEST_CONTENT_ANSWER_BODY2, TestContent_EN, TestContent_RO
} from "../constants/constants"
import {useLanguage} from "../LanguageContext";


export default function TestContent(props) {


    const sortedQuestions = props?.prop.questions?.sort((a, b) => a.questionId - b.questionId);

    const [messages, setMessages] = useState(TestContent_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(TestContent_EN);
        } else if (language === 'ro') {
            setMessages(TestContent_RO);
        }
    }, [language])

    return (
        <div>
            <div className=" w-full table-auto mx-auto max-w-screen-3xl px-2 pb-2
                    border-separate border-blue-400 border-4 rounded-lg
                    bg-cyan-50 mt-2">
                {sortedQuestions?.map((question, index) => (
                    <React.Fragment key={index}>
                        <div
                            className="h-full w-full overflow-x-auto justify-center items-center mt-2
                                    border-separate border-blue-400 border-2 rounded-lg"
                        >
                            <table
                                className="w-full table-auto mx-auto max-w-screen-3xl px-2 py-2
                                        rounded-lg bg-green-50">
                                <thead>
                                <tr className="w-full border-collapse">
                                    <th className={`${TEST_CONTENT_QUESTION_HEAD1}`}>{messages.QUESTION_NO}</th>
                                    <th className={`${TEST_CONTENT_QUESTION_HEAD2}`}>{messages.QUESTION}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="w-full border-collapse">
                                    <td className={`${TEST_CONTENT_QUESTION_BODY1}`}>{index+1}</td>
                                    <td className={`${TEST_CONTENT_QUESTION_BODY2}`}>{question.question}</td>
                                </tr>

                                </tbody>
                            </table>
                            {question.answers.sort((a, b) => a.answerId - b.answerId).map((answer, answerIndex) => (
                                <table
                                    className="w-full table-auto mx-auto max-w-screen-3xl px-2 py-2
                                            rounded-lg bg-yellow-50" key={answerIndex}>
                                    <thead>
                                    <tr className="w-full border-collapse">
                                        <th className={`${TEST_CONTENT_ANSWER_HEAD1}`}>{messages.ANSWER_NO}
                                        </th>
                                        <th className={`${TEST_CONTENT_ANSWER_HEAD2}`}>{messages.ANSWER}</th>
                                        <th className={`${TEST_CONTENT_ANSWER_HEAD1}`}>{messages.RIGHT_ANSWER}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="w-full border-collapse">
                                        <td className={`${TEST_CONTENT_ANSWER_BODY1}`}>{answerIndex+1}</td>
                                        <td className={`${TEST_CONTENT_ANSWER_BODY2}`}>{answer.answer}</td>
                                        <td className={`${TEST_CONTENT_ANSWER_BODY1}`}>{answer.rightAnswer ? messages.YES : messages.NO}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            ))}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
