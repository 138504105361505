import React, {useEffect, useState} from "react";

// import {LOGO} from "../constants/constants"
import ExamKeyButton from "./ExamKeyButton";
import NavList from "./NavList";

import {Button, Collapse, IconButton, List, Navbar, Typography,} from "@material-tailwind/react";

import {Bars3Icon, XMarkIcon,} from "@heroicons/react/24/outline";

import LOGO from '../images/logo.png';

import {useLanguage} from '../LanguageContext';
import {Navbar_EN, Navbar_RO} from "../constants/constants";

export default function NavbarFunction(key, value) {

    const [openNav, setOpenNav] = React.useState(false);

    const loggedUserId = sessionStorage.getItem("userId")

    const {language, changeLanguage} = useLanguage();

    const [messages, setMessages] = useState(Navbar_EN);


    useEffect(() => {
        if (language === 'en') {
            setMessages(Navbar_EN);
        } else if (language === 'ro') {
            setMessages(Navbar_RO);
        }
    }, [language])

    const handleChangeLanguage = () => {
        const newLanguage = language === 'en' ? 'ro' : 'en';
        changeLanguage(newLanguage);
    };


    let isUserLogged = false;

    if (loggedUserId != null) {
        isUserLogged = true
    }


    useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false)
        );
    }, []);


    const handleSignUpClick = () => {
        window.location.href = '/auth/register';
    };


    const handleSignInClick = () => {
        window.location.href = '/auth/login';
    };


    const handleLoggedUserClick = () => {
        window.location.href = `/users/user-id/${loggedUserId}`
    }


    return (
        <Navbar className="fixed top-0 mx-auto w-full max-w-screen-3xl px-4 py-2 z-50">
            <div className="flex items-center justify-between text-blue-gray-900">
                <div className="flex items-center">

                    <a href="/">
                        <img height="40" width="40" src={LOGO} alt="navbar images"
                             className="mr-0 cursor-pointer py-1.5 lg:ml-2 rounded-2xl"/>
                    </a>

                    <Typography
                        as="a"
                        href="/"
                        variant="h6"
                        className="mr-4 cursor-pointer py-1.5 ml-2"
                    >
                        {messages.TSTNOW}
                    </Typography>

                </div>

                <div className="hidden lg:block">
                    <NavList/>
                </div>

                <div className="hidden gap-2 lg:flex lg:w-40% lg:justify-end">

                    <Button variant="text" size="sm"
                            className="basis-1/6 bg-green-100 text-indigo-800 hover:bg-green-500 hover:text-white hover:-translate-y-0.5 transition-all duration-500"
                            onClick={handleChangeLanguage}
                    >
                        {language === "en" ? messages.EN : messages.RO}
                    </Button>


                    <div className="basis-1/2">
                        <ExamKeyButton/>
                    </div>

                    <Button variant="text" size="sm"
                            className="basis-1/4 bg-blue-100 text-indigo-800 hover:bg-blue-800 hover:text-white hover:-translate-y-0.5 transition-all duration-500"
                            onClick={handleSignInClick}
                    >
                        {messages.LOG_IN}
                    </Button>

                    <Button variant="text" size="sm"
                            className="basis-1/4 bg-indigo-100 text-indigo-800 hover:text-white hover:bg-indigo-800 hover:-translate-y-0.5 transition-all duration-500"
                            onClick={handleSignUpClick}
                    >
                        {messages.JOIN}

                    </Button>
                    {isUserLogged && <Button variant="text" size="sm"
                                             className="basis-1/6 bg-brown-100 flex items-center justify-center text-indigo-800 hover:text-white hover:bg-brown-500 hover:-translate-y-0.5 transition-all duration-500"
                                             onClick={handleLoggedUserClick}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/>
                        </svg>

                    </Button>}
                </div>

                <IconButton
                    variant="text"
                    color="blue-gray"
                    className="lg:hidden"
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (
                        <XMarkIcon className="h-6 w-6" strokeWidth={2}/>
                    ) : (
                        <Bars3Icon className="h-6 w-6" strokeWidth={2}/>
                    )}
                </IconButton>
            </div>

            <Collapse open={openNav}>
                <div className="max-h-60 overflow-y-auto">

                    <NavList/>

                    <List className="w-full mt-0 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 ">

                        <Button variant="text" size="sm"
                                className="basis-1/6 bg-blue-100 text-indigo-800 hover:bg-blue-800 hover:text-white hover:-translate-y-0.5 transition-all duration-500"
                                onClick={handleChangeLanguage}
                        >
                            {language === "en" ? messages.EN : messages.RO}
                        </Button>

                        <div className="mb-2">
                            <ExamKeyButton/>
                        </div>

                        <Button variant="outlined" size="sm" color="blue-gray" fullWidth className="mb-2"
                                onClick={handleSignInClick}
                        >
                            {messages.LOG_IN}
                        </Button>

                        <Button variant="gradient" size="sm" fullWidth className="mb-2"
                                onClick={handleSignUpClick}
                        >
                            {messages.JOIN}
                        </Button>

                        {isUserLogged && <Button variant="text" size="sm"
                                                 className="basis-1/7 bg-brown-100 flex items-center justify-center text-indigo-800 hover:text-white hover:bg-brown-500 hover:-translate-y-0.5 transition-all duration-500"
                                                 onClick={handleLoggedUserClick}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/>
                            </svg>
                            {messages.USER}
                        </Button>}

                    </List>
                </div>
            </Collapse>
        </Navbar>
    );
}
