import axios from "axios";

export function checkLogin() {

    let authToken = sessionStorage.getItem("JWT");
    let userID = sessionStorage.getItem("userId");
    if (authToken === null) {
        // This means that there ISN'T JWT and no user is logged in.
        axios.defaults.headers.common.Authorization = null;
    } else {
        // This means that there IS a JWT so someone must be logged in.
        axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    }
    return userID;
}

