import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from 'axios';
import {
    ACTION_BUTTON,
    AddNewTest_EN,
    AddNewTest_RO,
    LOCAL_URL,
    QUESTION_MAX_NO_OF_ANSWERS,
    QUESTION_MIN_NO_OF_ANSWERS,
    QUESTION_MIN_NO_OF_VALID_ANSWERS,
    SINGLE_TEST_BODY1,
    SINGLE_TEST_BODY2,
    SINGLE_TEST_HEAD1,
    SINGLE_TEST_HEAD2,
    TEST_CONTENT_ANSWER_BODY1,
    TEST_CONTENT_ANSWER_BODY2,
    TEST_CONTENT_ANSWER_HEAD1,
    TEST_CONTENT_ANSWER_HEAD2,
    TEST_CONTENT_QUESTION_BODY1,
    TEST_CONTENT_QUESTION_BODY2,
    TEST_CONTENT_QUESTION_HEAD1,
    TEST_CONTENT_QUESTION_HEAD2,
    TEST_MAX_NO_OF_QUESTIONS,
    TEST_MIN_NO_OF_QUESTIONS,
    TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS
} from "../constants/constants";
import {Button,} from "@material-tailwind/react";
import MessageRed from "./MessageRed";
import MessageGreen from "./MessageGreen";
import {checkLogin} from "./Util";
import {useLanguage} from "../LanguageContext";


export default function AddNewTest() {

    const {id} = useParams();

    const userId = id;

    let data = {};

    //TODO: use state class instead of multiple variables

    let [redMessage, setRedMessage] = useState("")
    let [showBadData, setShowBadData] = useState(false);
    const [dataChecked, setDataChecked] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [valueNumberOfQuestions, setValueNumberOfQuestions] = useState('');
    const [valueNumberOfAnswers, setValueNumberOfAnswers] = useState('');

    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState([]);
    const fragments = useState([]);


    const testNameRef = useRef("");
    const numberOfQuestionsRef = useRef(0);
    const numberOfAnswersForQuestionRef = useRef(0);
    const maxNumberOfValidAnswersRef = useRef(0);
    const admitQuestionsWithoutValidAnswerRef = useRef(null);

    let savedNewTest;
    let savedNewQuestion;


    const [messages, setMessages] = useState(AddNewTest_EN);

    const {language} = useLanguage();


    useEffect(() => {
        if (language === 'en') {
            setMessages(AddNewTest_EN);
        } else if (language === 'ro') {
            setMessages(AddNewTest_RO);
        }
    }, [language])


    useEffect(() => {
        checkLogin();
    }, []);
    // const loggedUserId = checkLogin();

    function checkInputData() {
        if (
            testNameRef.current?.value
            && numberOfQuestionsRef.current?.value
            && numberOfAnswersForQuestionRef.current?.value
            && maxNumberOfValidAnswersRef.current?.value) {
            return true;
        } else {
            setRedMessage(messages.COMPLETE_ALL_DATA);
            return false;
        }
    }


    function checkMinNoOfQuestions() {
        if (numberOfQuestionsRef.current?.value >= TEST_MIN_NO_OF_QUESTIONS) {
            return true;
        } else {
            setRedMessage(`${messages.NO_OF_QUESTIONS_NOT_SMALLER_THAN}${TEST_MIN_NO_OF_QUESTIONS}`);
            return false;
        }
    }


    function checkMaxNoOfQuestions() {
        if (numberOfQuestionsRef.current?.value <= TEST_MAX_NO_OF_QUESTIONS) {
            return true;
        } else {
            setRedMessage(`${messages.NO_OF_QUESTIONS_NOT_GREATER_THAN}${TEST_MAX_NO_OF_QUESTIONS}`);
            return false;
        }
    }


    function checkMinNoOfAnswers() {
        if (numberOfAnswersForQuestionRef.current?.value >= QUESTION_MIN_NO_OF_ANSWERS) {
            return true;
        } else {
            setRedMessage(`${messages.N0_OF_ANSWERS_NOT_SMALLER_THAN}${TEST_MIN_NO_OF_QUESTIONS}`);
            return false;
        }
    }


    function checkMaxNoOfAnswers() {
        if (numberOfAnswersForQuestionRef.current?.value <= QUESTION_MAX_NO_OF_ANSWERS) {
            return true;
        } else {
            setRedMessage(`${messages.N0_OF_ANSWERS_NOT_GREATER_THAN}${QUESTION_MAX_NO_OF_ANSWERS}`);
            return false;
        }
    }


    function checkNumberOfValidAnswers() {
        if (admitQuestionsWithoutValidAnswerRef.current?.value === "false") {
            if (maxNumberOfValidAnswersRef.current?.value >= QUESTION_MIN_NO_OF_VALID_ANSWERS
                && checkMaxNumberOfValidAnswers()) {
                return true;
            } else {
                setRedMessage(`${messages.NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN}${QUESTION_MIN_NO_OF_VALID_ANSWERS}`);
                return false;
            }
        } else if (admitQuestionsWithoutValidAnswerRef.current?.value === "true") {
            if (maxNumberOfValidAnswersRef.current?.value >= TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS
                && checkMaxNumberOfValidAnswers()) {
                return true;
            } else {
                setRedMessage(`${messages.NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN}${TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS}`);
                return false;
            }
        }
    }


    function checkMaxNumberOfValidAnswers() {
        if (maxNumberOfValidAnswersRef.current?.value <= numberOfAnswersForQuestionRef.current.value) {
            return true;
        } else {
            setRedMessage(`${messages.NO_OF_VALID_ANSWERS_NOT_GREATER_THAN}${numberOfAnswersForQuestionRef.current.value}`);
            return false;
        }
    }


    async function checkForTestNameHasNoDuplicates() {
        let dataFetchSuccess = true;

        await axios.get(`${LOCAL_URL}/api/users/user-id/${userId}`)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setRedMessage('Error fetching data: ' + error.message);
                setShowBadData(true);
                dataFetchSuccess = false;
            });

        if (!dataFetchSuccess) {
            return false; // Exit the function if there was an error fetching data
        }

        for (const test of data?.setOfTests) {
            const testName = test.testName;

            if (testNameRef.current?.value === testName) {
                setRedMessage(`${messages.TEST_NAME_ALREADY_USED}`);
                return false
            }
        }
        return true;
    }

    function checkAllAnswersHaveQuestion() {

        const filteredOfNullQuestions = questions.filter(element => element !== "" && element !== undefined);
        const filteredOfNullAnswers = answers.filter(element => element !== "" && element !== undefined);

        if (filteredOfNullQuestions.length < numberOfQuestionsRef.current?.value
            || filteredOfNullAnswers.length < numberOfQuestionsRef.current?.value * numberOfAnswersForQuestionRef.current?.value
        ) {
            setRedMessage(`${messages.INPUT_ALL_DATA}`);
            return false;
        }
        return true;
    }


    function checkQuestionNoOfValidAnswers() {
        let questionNo = 0;
        for (let i = 0; i < checkboxStates.length; i += parseInt(numberOfAnswersForQuestionRef.current.value, 10)) {
            questionNo++;
            let partialArray = [];
            partialArray = checkboxStates.slice(i, i + parseInt(numberOfAnswersForQuestionRef.current.value, 10));
            let noOfQuestionRightAnswers = partialArray.filter((value) => value === true).length;

            if (noOfQuestionRightAnswers > parseInt(maxNumberOfValidAnswersRef.current.value, 10)) {
                setRedMessage(`${messages.INVALID_NO_OF_RIGHT_ANSWERS}${questionNo}!`);
                return false;
            } else if (noOfQuestionRightAnswers <= parseInt(maxNumberOfValidAnswersRef.current.value, 10)) {
                if (admitQuestionsWithoutValidAnswerRef.current.value === "true") {
                    if (noOfQuestionRightAnswers < 0) {
                        setRedMessage(`${messages.NO_OF_RIGHT_ANSWERS_BETWEEN_0_AND}${maxNumberOfValidAnswersRef.current.value}${messages.FOR_QUESTION}${questionNo}!`);
                        return false;
                    }
                } else if (admitQuestionsWithoutValidAnswerRef.current.value === "false") {
                    if (noOfQuestionRightAnswers < 1) {
                        setRedMessage(`${messages.NO_OF_RIGHT_ANSWERS_BETWEEN_1_AND}${maxNumberOfValidAnswersRef.current.value}${messages.FOR_QUESTION}${questionNo}!`);
                        return false;
                    }
                }
            }
        }
        return true;
    }


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };


    useEffect(() => {
        if (admitQuestionsWithoutValidAnswerRef.current) {
            admitQuestionsWithoutValidAnswerRef.current.value = isChecked ? 'true' : 'false';
        }
    }, [isChecked]);


    const handleChange1 = (event) => {
        let inputValue = event.target.value;

        // Remove non-numeric characters
        inputValue = inputValue.replace(/[^0-9]/g, '');

        setValueNumberOfQuestions(inputValue);

        //check for valueNumberOfAnswers and initialize checkboxStates with false
        if (valueNumberOfAnswers) {

            const initialCheckboxStates = new Array(
                numberOfAnswersForQuestionRef?.current?.value * numberOfQuestionsRef?.current?.value
            ).fill(false);

            setCheckboxStates(initialCheckboxStates);

            const initialQuestionsStates = new Array(numberOfQuestionsRef.current?.value).fill("");
            setQuestions(initialQuestionsStates);

            const initialAnswersStates = new Array(numberOfAnswersForQuestionRef.current?.value).fill("");
            setAnswers(initialAnswersStates);
        }
    };


    const handleChange2 = (event) => {
        let inputValue = event.target.value;

        // Remove non-numeric characters
        inputValue = inputValue.replace(/[^0-9]/g, '');

        setValueNumberOfAnswers(inputValue);

        // check for valueNumberOfQuestions and initialize checkboxStates with false
        if (valueNumberOfQuestions) {

            const initialCheckboxStates = new Array(
                numberOfAnswersForQuestionRef?.current?.value * numberOfQuestionsRef?.current?.value
            ).fill(false);

            setCheckboxStates(initialCheckboxStates);

            const initialQuestionsStates = new Array(numberOfQuestionsRef.current?.value).fill("");
            setQuestions(initialQuestionsStates);

            const initialAnswersStates = new Array(numberOfQuestionsRef.current?.value * numberOfAnswersForQuestionRef.current?.value).fill("");
            setAnswers(initialAnswersStates);
        }
    }


    //TODO: error message in the case of data not persisted

    async function addNewTest(e) {
        e.preventDefault();

        if (checkInputData()
            && checkMinNoOfQuestions()
            && checkMaxNoOfQuestions()
            && checkMinNoOfAnswers()
            && checkMaxNoOfAnswers()
            && checkNumberOfValidAnswers()
            && checkAllAnswersHaveQuestion()
            && checkQuestionNoOfValidAnswers()
            && await checkForTestNameHasNoDuplicates()
        ) {
            setShowBadData(false);
            setDataChecked(true)
            try {
                const responseTest = await axios.post(`${LOCAL_URL}/api/tests/${userId}`, {
                    testName: testNameRef.current.value,
                    numberOfQuestions: numberOfQuestionsRef.current.value,
                    numberOfAnswersForQuestion: numberOfAnswersForQuestionRef.current.value,
                    maxNumberOfValidAnswers: maxNumberOfValidAnswersRef.current.value,
                    admitQuestionsWithoutValidAnswer: admitQuestionsWithoutValidAnswerRef.current.value,
                });

                savedNewTest = responseTest.data;

            } catch (error) {
                console.error(error);
                setRedMessage(`${messages.ERROR_WRITING_TEST_TO_DB}` + error.message);
                setShowBadData(true);

            }


            for (let i = 0; i < savedNewTest.numberOfQuestions; i++) {
                if (questions[i]) {
                    try {
                        const responseQuestion =
                            await axios.put(`${LOCAL_URL}/api/questions/${savedNewTest.testId}`, {
                                question: questions[i],
                            });

                        savedNewQuestion = responseQuestion.data;

                    } catch (error) {

                        console.error(error);
                        setRedMessage(`${messages.ERROR_WRITING_QUESTION_TO_DB}` + error.message);
                        setShowBadData(true);

                        //TODO: it is ok?
                        await axios.delete(`${LOCAL_URL}/api/tests/${savedNewTest.testId}`)
                            .then(function (response) {
                                // Handle success, e.g., update UI or perform other tasks
                                console.log(`${messages.DELETED_SUCCESSFULLY}`, response);
                                window.location.href = `/tests/delete`;
                            })
                            .catch(function (error) {
                                // Handle error, e.g., show an error message to the user
                                console.error(`${messages.ERROR_DELETING_RESOURCE}`, error);
                                setRedMessage(`${messages.ERROR_DELETING_RESOURCE}` + error.message);
                                setShowBadData(true);
                            });
                    }
                }

                for (let j = 0; j < savedNewTest.numberOfAnswersForQuestion; j++) {
                    if (answers[savedNewTest.numberOfAnswersForQuestion * i + j]) {
                        try {
                            await axios.put(`${LOCAL_URL}/api/answers/${savedNewQuestion?.questionId}`, {
                                answer: answers[savedNewTest.numberOfAnswersForQuestion * i + j],
                                rightAnswer: checkboxStates[savedNewTest.numberOfAnswersForQuestion * i + j]
                            });
                        } catch (error) {
                            console.error(error);
                            setRedMessage(`${messages.ERROR_WRITING_ANSWER_TO_DB}` + error.message);
                            setShowBadData(true);


                            //TODO: it is ok?
                            await axios.delete(`${LOCAL_URL}/api/tests/${savedNewTest.testId}`)
                                .then(function (response) {
                                    // Handle success, e.g., update UI or perform other tasks
                                    console.log(`${messages.DELETED_SUCCESSFULLY}`, response);

                                    window.location.href = `/tests/delete`;
                                })
                                .catch(function (error) {
                                    // Handle error, e.g., show an error message to the user
                                    console.error(`${messages.ERROR_DELETING_RESOURCE}`, error);
                                    setRedMessage(`${messages.ERROR_DELETING_RESOURCE}` + error.message);
                                    setShowBadData(true);
                                });
                        }
                    }
                }
            }

            window.location.href = `/tests/${savedNewTest.testId}`;

        } else {
            setShowBadData(true);
        }
    }


    const writeTestStructure = () => {

        for (let i = 0; i < numberOfQuestionsRef.current?.value; i++) {
            const questionRows = [];
            const answerRows = [];
            questionRows.push(
                <tr id="question" className="w-full border-collapse" key={i}>
                    <td className={TEST_CONTENT_QUESTION_BODY1}>{i + 1}</td>
                    <td className={TEST_CONTENT_QUESTION_BODY2}>
                        <input
                            type="text"
                            className="form-control w-full"
                            value={questions[i]}
                            onChange={(e) => {
                                const updatedQuestions = [...questions];
                                updatedQuestions[i] = e.target.value;
                                setQuestions(updatedQuestions);
                            }}
                        />
                    </td>
                </tr>
            );


            for (let j = 0; j < numberOfAnswersForQuestionRef?.current?.value; j++) {
                answerRows.push(
                    <tr id="answer" className="w-full border-collapse" key={j}>

                        <td className={TEST_CONTENT_ANSWER_BODY1}>{j + 1}</td>
                        <td className={TEST_CONTENT_ANSWER_BODY2}>
                            <input
                                type="text"
                                className="form-control w-full"
                                value={answers[numberOfAnswersForQuestionRef?.current?.value * i + j]}
                                onChange={(e) => {
                                    const updatedAnswers = [...answers];
                                    updatedAnswers[numberOfAnswersForQuestionRef?.current?.value * i + j] = e.target.value;
                                    setAnswers(updatedAnswers);
                                }}
                            />
                        </td>

                        <td className={TEST_CONTENT_ANSWER_BODY1}>
                            <div className="form-control flex items-center justify-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox w-full h-4 "
                                    checked={checkboxStates[numberOfAnswersForQuestionRef?.current?.value * i + j]}
                                    onChange={() => {
                                        const updatedCheckers = [...checkboxStates];
                                        updatedCheckers[numberOfAnswersForQuestionRef?.current?.value * i + j] =
                                            !checkboxStates[numberOfAnswersForQuestionRef?.current?.value * i + j];
                                        setCheckboxStates(updatedCheckers);
                                    }}
                                />
                            </div>
                        </td>

                    </tr>
                )
            }


            fragments.push(
                <React.Fragment>
                    <div
                        className="h-full w-full overflow-y-auto justify-center items-center mt-2
                                    border-separate border-blue-400 border-2 rounded-lg">

                        <table
                            className="w-full table-auto mx-auto max-w-screen-3xl px-2 py-2
                                        rounded-lg bg-green-50">

                            <thead>
                            <tr className="w-full border-collapse">
                                <th className={TEST_CONTENT_QUESTION_HEAD1}>{messages.QUESTION_NO}</th>
                                <th className={TEST_CONTENT_QUESTION_HEAD2}>{messages.QUESTION}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {questionRows}
                            </tbody>

                        </table>

                        <table
                            className="w-full table-auto mx-auto max-w-screen-32xl px-2 py-2
                                            rounded-lg bg-yellow-50">

                            <thead>
                            <tr className="w-full border-collapse">
                                <th className={TEST_CONTENT_ANSWER_HEAD1}>{messages.ANSWER_NO}</th>
                                <th className={TEST_CONTENT_ANSWER_HEAD2}>{messages.ANSWER}</th>
                                <th className={TEST_CONTENT_ANSWER_HEAD1}>{messages.RIGHT_ANSWER}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {answerRows}
                            </tbody>

                        </table>

                    </div>
                </React.Fragment>
            );
        }
        return fragments;
    }


    return (
        <div className="mt-16 ">
            <form className="col-md-6" onSubmit={addNewTest}>
                {/*<div className="flex flex-col space-y-2">*/}
                <div className="flex flex-col">
                    <div className="mt-2 z-20 w-full">
                    {/*<div className="mt-2 w-full">*/}
                        <div className="overflow-hidden">
                            <table className="table-auto mx-auto max-w-screen-3xl px-2 py-2
                        border-separate border-blue-400 border-4 rounded-lg
                        bg-cyan-50 " onChange={writeTestStructure}>

                                <thead>
                                <tr className="w-full border-collapse">
                                    <th className={SINGLE_TEST_HEAD1}>{messages.TEST_NAME}</th>
                                    <th className={SINGLE_TEST_HEAD2}>{messages.NO_OF_QUESTIONS}</th>
                                    <th className={SINGLE_TEST_HEAD2}>{messages.NO_OF_ANSWERS_FOR_QUESTION}</th>
                                    <th className={SINGLE_TEST_HEAD2}>{messages.MAX_NO_VALID_ANSWERS}</th>
                                    <th className={SINGLE_TEST_HEAD2}>{messages.ADMIT_Q_WITHOUT_VALID_ANSWER}</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr className="w-full border-collapse">
                                    <td className={SINGLE_TEST_BODY1}>
                                        <input
                                            type="text"
                                            ref={testNameRef}
                                            className="form-control w-full"/>
                                    </td>
                                    <td className={SINGLE_TEST_BODY2}>
                                        <input
                                            type="number"
                                            min={3}
                                            max={100}
                                            defaultValue={3}
                                            // value={valueNumberOfQuestions}
                                            onChange={handleChange1}
                                            ref={numberOfQuestionsRef}
                                            className="form-control w-full"/>
                                    </td>
                                    <td className={SINGLE_TEST_BODY2}>
                                        <input
                                            type="number"
                                            min={3}
                                            max={6}
                                            defaultValue={3}
                                            // value={valueNumberOfAnswers}
                                            onChange={handleChange2}
                                            ref={numberOfAnswersForQuestionRef}
                                            className="form-control w-full"/>
                                    </td>
                                    <td className={SINGLE_TEST_BODY2}>
                                        <input type="number"
                                               min={1}
                                               max={6}
                                               ref={maxNumberOfValidAnswersRef}
                                               className="form-control w-full"/>
                                    </td>
                                    <td className={SINGLE_TEST_BODY2}>
                                        <div className="form-control flex items-center justify-center">
                                            <input
                                                type="checkbox"
                                                ref={admitQuestionsWithoutValidAnswerRef}
                                                className="form-checkbox w-5 h-5"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        {/*</div>*/}

                        {/*<div className="relative custom-height">*/}
                        <div className="relative">
                            {/*<div className="mt-52 sm:mt-46 md:mt-40 lg:mt-40 xl:mt-34 absolute inset-0">*/}
                            {/*<div className="mt-0 absolute inset-0">*/}
                            <div className="mt-2 relative">
                                <div>
                                    {
                                        (
                                            (numberOfQuestionsRef.current?.value >= 3)
                                            && (numberOfQuestionsRef.current?.value <= 100)
                                            && (numberOfAnswersForQuestionRef.current?.value >= 3)
                                            && (numberOfAnswersForQuestionRef.current?.value <= 6)
                                            && (testNameRef.current?.value !== "")
                                            && (maxNumberOfValidAnswersRef.current?.value >= 0)
                                            && (maxNumberOfValidAnswersRef.current?.value <= numberOfAnswersForQuestionRef.current?.value)
                                        ) ? (
                                            <div className=" w-full h-full table-auto mx-auto max-w-screen-3xl px-2 pb-2
                                 border-separate border-blue-400 border-4 rounded-lg
                                 bg-cyan-50">
                                                {writeTestStructure().map((item, index) => (
                                                    <div key={index}>
                                                        {item}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            redMessage = `${messages.NO_OF_Q_BTW_AND_NO_OF_A_BTW}`,
                                                showBadData = true
                                        )}
                                </div>
                            </div>
                            {/*</div>*/}

                            <div className="mt-2">
                                {showBadData ? (
                                    <div>
                                        <MessageRed message={redMessage}/>
                                    </div>
                                ) : (
                                    <div>
                                        {dataChecked ? (
                                            <div>
                                                <MessageGreen message={messages.TEST_SAVED}/>
                                            </div>
                                        ) : (
                                            <div>
                                                <MessageGreen message={messages.COMPLETE_TEST_DATA}/>
                                            </div>
                                        )
                                        }
                                    </div>
                                )}

                                <div className="mt-2">
                                    <Button type="submit"
                                            size="lg" fullWidth
                                        // className="basis-1/4 mt-4"
                                            className={ACTION_BUTTON}
                                            onClick={addNewTest}
                                    >
                                        {messages.SAVE_TEST}
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    );
}
