import React, {useEffect, useState} from "react";
import { Input, Button } from "@material-tailwind/react";
import {useLanguage} from "../LanguageContext";
import {ExamKeyButton_EN, ExamKeyButton_RO} from "../constants/constants";

export default function ExamKeyButton() {
    const [examKey, setExamKey] = React.useState("");
    const onChange = ({ target }) => setExamKey(target.value);

    const {language} = useLanguage();

    const [messages, setMessages] = useState(ExamKeyButton_EN);

    useEffect(() => {
        if (language === 'en') {
            setMessages(ExamKeyButton_EN);
        } else if (language === 'ro') {
            setMessages(ExamKeyButton_RO);
        }
    }, [language])


    return (
        <div className="relative flex w-full hover:-translate-y-0.5 transition-all duration-500">
            <Input
                type="examKey"
                label={messages.EXAM_KEY}
                value={examKey}
                onChange={onChange}
                className="pr-20"
                containerProps={{
                    className: "min-w-0",
                }}
            />
            <Button
                size="sm"
                color={examKey ? "green" : "amber"}
                disabled={!examKey}
                className="!absolute right-1 top-1 rounded transition-all duration-500"
            >
                {messages.GET_TEST}
            </Button>
        </div>
    );
}
