import React from "react";
import AddNewTest from "../components/AddNewTest";

export default function AddTestPage() {

    return (
        <div className="single_test">
            <AddNewTest/>
        </div>
    )
}
