import React, {useEffect, useState} from 'react';
import MessageRed from "./MessageRed";
import {TimeToLogIn_EN, TimeToLogIn_RO} from "../constants/constants";
import {useLanguage} from "../LanguageContext";


export default function TimeToLogIn() {

    const [redMessage, setRedMessage] = useState("");

    const [showBadData, setShowBadData] = useState(false);

    const [messages, setMessages] = useState(TimeToLogIn_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(TimeToLogIn_EN);
        } else if (language === 'ro') {
            setMessages(TimeToLogIn_RO);
        }
    }, [language])


    useEffect(
        () => {
            setRedMessage(messages.RED_MESSAGE)
            setShowBadData(true);
            setTimeout(() => {
                sessionStorage.clear();
                window.location.href = "/auth/login"
            }, 2000)

        }, []
    )

    return (
        <div className="sign-up-page">
            <div className="bg-blue-gray-50 min-h-screen flex flex-col">
                <div className="container mb-5 max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                    <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
                        <div className="flex items-center justify-center">
                            <div
                                className=" text-orange-500 mb-5 h-8 w-8 animate-spin rounded-full
                                border-4 border-solid border-current border-e-transparent align-[-0.125em]
                                text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                            </div>
                        </div>
                        <div className="mb-4">
                            {showBadData && (<div>
                                <MessageRed message={redMessage}/>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

