import React, {useEffect, useState} from "react";
import {Chip, Collapse, ListItem, Menu, MenuHandler, MenuItem, MenuList, Typography} from "@material-tailwind/react";
import {
    ChatBubbleOvalLeftIcon,
    ChevronDownIcon,
    FlagIcon,
    FolderIcon,
    GiftIcon,
    RocketLaunchIcon,
    Square3Stack3DIcon,
    UsersIcon
} from "@heroicons/react/24/outline";

import {COLORS, SiteResourcesMenu_EN, SiteResourcesMenu_RO} from "../constants/constants";
import {useLanguage} from "../LanguageContext";


export default function SiteResourcesMenu() {

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

    const {language} = useLanguage();
    const [messages, setMessages] = useState(SiteResourcesMenu_EN);

    useEffect(() => {
        if (language === 'en') {
            setMessages(SiteResourcesMenu_EN);
        } else if (language === 'ro') {
            setMessages(SiteResourcesMenu_RO);
        }
    }, [language])

    const resourcesListMenuItems = [
        {
            color: "blue",
            icon: FlagIcon,
            title: messages.ABOUT_US,
            description: messages.ABOUT_US_DESCR,
        },
        {
            color: "orange",
            icon: ChatBubbleOvalLeftIcon,
            title: messages.PRESS,
            description: messages.PRESS_DESCR,
        },
        {
            color: "green",
            icon: UsersIcon,
            title: (
                <div className="flex items-center gap-1">
                    Careers{" "}
                    <Chip
                        size="sm"
                        color="green"
                        variant="ghost"
                        value={messages.HIRING}
                        className="capitalize"
                    />
                </div>
            ),
            description: messages.HIRING_DESCR,
        },
        {
            color: "blue-gray",
            icon: FolderIcon,
            title: messages.LEGAL,
            description: messages.LEGAL_DESCR,
        },
        {
            color: "purple",
            icon: RocketLaunchIcon,
            title: messages.PRODUCTS,
            description: messages.PRESS_DESCR,
        },
        {
            color: "pink",
            icon: GiftIcon,
            title: messages.OPEN_SOURCE,
            description: messages.OPEN_SOURCE_DESCR,
        },
    ];


    const renderItems = resourcesListMenuItems.map(
        ({icon, title, description, color, href}, key) => (
            <a href={href} key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg transition-all duration-500">
                    <div className={`rounded-lg p-5 ${COLORS[color]}`}>
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 w-6",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="flex items-center text-sm"
                        >
                            {title}
                        </Typography>
                        <Typography variant="small" color="gray" className="font-normal">
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </a>
        )
    );

    return (
        <React.Fragment>
            <div className="max-h-60 overflow-y-auto">
                <Menu
                    open={isMenuOpen}
                    handler={setIsMenuOpen}
                    offset={{mainAxis: 20}}
                    placement="bottom"
                    allowHover={true}
                >
                    <MenuHandler>
                        <Typography as="div" variant="small"
                                    className="font-normal hover:-translate-y-0.5 transition-all duration-500">
                            <ListItem
                                className="flex items-center gap-2 py-2 pr-4"
                                selected={isMenuOpen || isMobileMenuOpen}
                                onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                            >
                                <Square3Stack3DIcon className="h-[18px] w-[18px]"/>
                                {messages.SITE_RESOURCES}
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`hidden h-3 w-3 transition-transform lg:block ${
                                        isMenuOpen ? "rotate-180" : ""
                                    }`}
                                />
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`block h-3 w-3 transition-transform lg:hidden ${
                                        isMobileMenuOpen ? "rotate-180" : ""
                                    }`}
                                />
                            </ListItem>
                        </Typography>
                    </MenuHandler>
                    <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                        <ul className="grid grid-cols-4 gap-y-2">{renderItems}</ul>
                    </MenuList>
                </Menu>
                <div className="block lg:hidden">
                    <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
                </div>
            </div>
        </React.Fragment>
    );
}
