import React from "react";

export default function TermsOfServicePage() {

    return (
        <div className="flex items-center justify-center text-center text-3xl text-red-600 bg-red-50 h-full
            rounded-md mx-auto w-screen mt-20">
            <h1 className="font-bold">
                Terms Of Service Page
            </h1>
        </div>
    )
}
