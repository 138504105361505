import React from "react";
import CarouselTransition from "../components/CarouselTransition";


export default function LandingPage() {

    return (
        <div className="landing_page">
            <CarouselTransition/>
        </div>
    )
}




