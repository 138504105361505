import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useParams} from "react-router-dom";

import TestContent from "./TestContent";

import {
    ALL_TESTS_HEAD1,
    ALL_TESTS_HEAD2,
    LOCAL_URL,
    SINGLE_TEST_BODY1,
    SINGLE_TEST_BODY2,
    SingleTestData_EN,
    SingleTestData_RO
} from "../constants/constants"

import MessageRed from "./MessageRed";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import ExportConfirmationDialog from "./ExportConfirmationDialog";
import {checkLogin} from "./Util";
import {useLanguage} from "../LanguageContext";

export default function SingleTestData() {

    const {id} = useParams();

    const [data, setData] = useState([]);

    const url = `${LOCAL_URL}/api/tests/${id}`;

    const hrefOne = `/tests/${id}`;

    const hrefAllForUser = `/tests/user`;

    const [showConfirmation, setShowConfirmation] = useState(false);

    let [redMessage, setRedMessage] = useState("")
    let [showBadData, setShowBadData] = useState(false);

    const [showMessage, setShowMessage] = useState(false);
    const [showExportConfirmation, setShowExportConfirmation] = useState(false);
    const [message, setMessage] = useState("");
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({x: 0, y: 0})

    const [messages, setMessages] = useState(SingleTestData_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(SingleTestData_EN);
        } else if (language === 'ro') {
            setMessages(SingleTestData_RO);
        }
    }, [language])


    useEffect(() => {
        checkLogin();  //const userId = checkLogin();
    }, [])


    useEffect(() => {
        axios.get(url)
            .then(response => {
                const testData = response.data;
                setData(testData);
            })
            .catch(error => {
                console.error(message.ERROR_FETCHING_DATA, error);
                setRedMessage(message.ERROR_FETCHING_DATA + error.message);
                setShowBadData(true);
            });
    }, [message.ERROR_FETCHING_DATA, url]);


    const handleDeleteClick = () => {
        setShowConfirmation(true);
    };


    const handleButtonEdit = () => {
        window.location.href = `/tests/${data?.testId}/update`;
    }


    const handleMouseEnter = (e, messageType) => {

        switch (messageType) {
            case "updateButton":
                setMessage(messages.UPDATE_TEST);
                break;
            case "deleteButton":
                setMessage(messages.DELETE_TEST);
                break;
            case "downloadButton":
                setMessage(messages.DOWNLOAD_CSV);
                break;
            case 'selectTest':
                setMessage(messages.TEST_DETAILS);
                break;
            default:
                setMessage("");
        }

        setIsHovered(true);

        setTimeout(() => {
            setShowMessage(true);
        }, 1000); // Set the delay in milliseconds (1 second in this example)
    }

    const handleMouseLeave = () => {
        setIsHovered(false);
        setShowMessage(false);
    };

    const handleMouseMove = (e) => {
        if (isHovered) {
            setPosition({x: e.clientX + 15, y: e.clientY + 15}); // Adjust the offset as needed
        }
    };

    function handleButtonExport() {
        setShowExportConfirmation(true);
    }

    return (
        <div className="mt-18">
            <table className="table-auto mt-4 mx-auto max-w-screen-3xl px-2 py-2
                        border-separate border-blue-400 border-4 rounded-lg
                        bg-cyan-50">
                <thead>
                <tr className="w-full border-collapse">
                    <th className={`${ALL_TESTS_HEAD1} w-9/12`}>{messages.TEST_NAME}</th>
                    <th className={`${ALL_TESTS_HEAD2}`}>{messages.NO_OF_QUESTIONS}</th>
                    <th className={`${ALL_TESTS_HEAD2}`}>{messages.NO_OF_ANSWERS_FOR_QUESTION}</th>
                    <th className={`${ALL_TESTS_HEAD2}`}>{messages.MAX_NO_VALID_ANSWERS}</th>
                    <th className={`${ALL_TESTS_HEAD2}`}>{messages.ADMIT_Q_WITHOUT_VALID_ANSWER}</th>
                </tr>
                </thead>
                <tbody>
                <tr className="w-full border-collapse" key={data?.testId}>
                    <td className={`${SINGLE_TEST_BODY1} relative`}>
                        <div className="w-9/12">
                            {data.testName}
                        </div>
                        <div className="absolute right-1 top-1/2 transform -translate-y-1/2 space-x-2">
                            <button
                                className="hidden lg:inline text-right bg-blue-500 text-white px-2 py-1 rounded"
                                onClick={handleButtonEdit}
                                onMouseEnter={(e) => handleMouseEnter(e, "updateButton")}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                </svg>

                            </button>

                            <button
                                className="hidden lg:inline text-right bg-green-500 text-white px-1 py-1 rounded"
                                onClick={handleButtonExport}
                                onMouseEnter={(e) => handleMouseEnter(e, "downloadButton")}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm5.845 17.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V12a.75.75 0 0 0-1.5 0v4.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z"/>
                                </svg>
                            </button>

                            <button
                                className="hidden lg:inline text-right bg-red-500 text-white px-2 py-1 rounded mr-2"
                                onClick={handleDeleteClick}
                                onMouseEnter={(e) => handleMouseEnter(e, "deleteButton")}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                                </svg>

                            </button>
                        </div>
                    </td>
                    <td className={`${SINGLE_TEST_BODY2}`}>{data?.numberOfQuestions}</td>
                    <td className={`${SINGLE_TEST_BODY2}`}>{data?.numberOfAnswersForQuestion}</td>
                    <td className={`${SINGLE_TEST_BODY2}`}>{data?.maxNumberOfValidAnswers}</td>
                    <td className={`${SINGLE_TEST_BODY2}`}>{data?.admitQuestionsWithoutValidAnswer ? messages.YES : messages.NO}</td>
                </tr>
                </tbody>
            </table>


            {showConfirmation &&
                <DeleteConfirmationDialog
                    setShowConf={setShowConfirmation}
                    id={data?.testId}
                    href={hrefAllForUser}
                />
            }

            {isHovered && showMessage && (
                <div
                    className="absolute bg-orange-50 border-2 border-deep-purple-400 text-deep-purple-700 p-2 rounded-md"
                    style={{
                        position: 'fixed'
                        , left: `${position.x}px`
                        , top: `${position.y}px`
                    }}>
                    {message}
                </div>
            )}

            {showExportConfirmation &&
                <ExportConfirmationDialog
                    setShowConf={setShowExportConfirmation}
                    id={data?.testId}
                    href={hrefOne}
                />
            }

            <div>
                {data?.testId && data?.questions.length > 0 && (
                    <TestContent prop={data}/>
                )}
            </div>
            <div className="mt-4">
                {data?.testId && data?.questions?.length === 0 && (
                    <MessageRed message={messages.TEST_WITHOUT_DATA} no data/>
                )}
            </div>
            <div className="mt-4">
                {showBadData && (
                    <div>
                        <MessageRed message={redMessage}/>
                    </div>
                )}
            </div>
        </div>
    );
}
