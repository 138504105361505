import React, {useEffect, useState} from "react";
import {Carousel} from "@material-tailwind/react";
import {imagesList} from "./RenderCarouselImages";
import {CarouselTransition_EN, CarouselTransition_RO} from "../constants/constants";
import {useLanguage} from "../LanguageContext";


export default function CarouselTransition() {

    const [messages, setMessages] = useState(CarouselTransition_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(CarouselTransition_EN);
        } else if (language === 'ro') {
            setMessages(CarouselTransition_RO);
        }
    }, [language])

    return (
        <div className="flex flex-row mt-16">
            <div className=" lg:basis-1/2 rounded-lg p-5 bg-blue-50 md:mr-1 md:mt-2">
                <article className="prose lg:prose-xl p-10 text-justify w-full mx-auto">
                    <h3 className="text-blue-600">
                        {messages.TITLE}
                        {/*«This project is created to support educators and students in any subject where grid tests prove*/}
                        {/*beneficial.»*/}

                    </h3>
                    <h3 className="font-bold text-orange-700">
                        {messages.SUBTITLE}
                        {/*Ease of use:*/}
                    </h3>
                    <p>
                        <span className="font-bold text-blue-600">{messages.ONLINE_EXAMS}</span>
                        {messages.ONLINE_EXAMS_DESCR}
                        <br/><span className="font-bold text-blue-600">{messages.USER_FRIENDLY}</span>
                        {messages.USER_FRIENDLY_DESCR}
                        <br/>
                        <span className="font-bold text-blue-600">{messages.SUITABLE_FOR_EVERY_LEARNER}</span>
                        {messages.SUITABLE_FOR_EVERY_LEARNER_DESCR}
                    </p>
                </article>
            </div>

            <div className="hidden lg:inline h-100 basis-1/2 gap-3 md:ml-1  md:mt-2">

                <Carousel
                    transition={{duration: 2}}
                    autoplay={true}
                    autoplayDelay={5000}
                    loop={true}
                    className="rounded-md">
                    {imagesList}
                </Carousel>
            </div>
        </div>
    )
        ;
}


/*

//TODO: cross-fade carousel new ideea

// Initialization for ES Users
import {
    Carousel,
    initTWE,
} from "tw-elements";

initTWE({ Carousel });

<div
    id="carouselExampleCrossfade"
    className="relative"
    data-twe-carousel-init
    data-twe-ride="carousel">
    <!--Carousel indicators-->
    <div
        className="absolute inset-x-0 bottom-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0"
        data-twe-carousel-indicators>
        <button
            type="button"
            data-twe-target="#carouselExampleCrossfade"
            data-twe-slide-to="0"
            data-twe-carousel-active
            className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
            aria-current="true"
            aria-label="Slide 1"></button>
        <button
            type="button"
            data-twe-target="#carouselExampleCrossfade"
            data-twe-slide-to="1"
            className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
            aria-label="Slide 2"></button>
        <button
            type="button"
            data-twe-target="#carouselExampleCrossfade"
            data-twe-slide-to="2"
            className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
            aria-label="Slide 3"></button>
    </div>

    <!--Carousel items-->
    <div
        className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
        <!--First item-->
        <div
            className="relative float-left -mr-[100%] w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-twe-carousel-fade
            data-twe-carousel-item
            data-twe-carousel-active>
            <img
                src="https://mdbcdn.b-cdn.net/img/new/slides/041.webp"
                className="block w-full"
                alt="Wild Landscape"/>
        </div>
        <!--Second item-->
        <div
            className="relative float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-twe-carousel-fade
            data-twe-carousel-item>
            <img
                src="https://mdbcdn.b-cdn.net/img/new/slides/042.webp"
                className="block w-full"
                alt="Camera"/>
        </div>
        <!--Third item-->
        <div
            className="relative float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
            data-twe-carousel-fade
            data-twe-carousel-item>
            <img
                src="https://mdbcdn.b-cdn.net/img/new/slides/043.webp"
                className="block w-full"
                alt="Exotic Fruits"/>
        </div>
    </div>

    <!--Carousel controls - prev item-->
    <button
        className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
        type="button"
        data-twe-target="#carouselExampleCrossfade"
        data-twe-slide="prev">
    <span className="inline-block h-8 w-8">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="h-6 w-6">
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"/>
      </svg>
    </span>
        <span
            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Previous</span
        >
    </button>
    <!--Carousel controls - next item-->
    <button
        className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
        type="button"
        data-twe-target="#carouselExampleCrossfade"
        data-twe-slide="next">
    <span className="inline-block h-8 w-8">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="h-6 w-6">
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
      </svg>
    </span>
        <span
            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Next</span
        >
    </button>
</div>
*/
