import React, {useEffect, useState} from "react";
import Papa from 'papaparse';
import img from '../images/4.jpg';
import {ImportTestPage_EN, ImportTestPage_RO} from "../constants/constants";
import {useLanguage} from "../LanguageContext";


export default function ImportTestPage() {

    const [testData, setTestData] = useState([]);

    let questionsList = [];
    let answersList = [];
    let validAnswerList = [];
    let updatedTestData = [];

    const [messages, setMessages] = useState(ImportTestPage_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(ImportTestPage_EN);
        } else if (language === 'ro') {
            setMessages(ImportTestPage_RO);
        }
    }, [language])



    useEffect(() => {
        // This code will run after the state has been updated
        sessionStorage.setItem('testData', JSON.stringify(testData));
    }, [testData]);


    const handleFileChange = (e) => {


        let countRecordLines = 0;
        const file = e.target.files[0];
        let numberOfAnswersForQuestion;

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const csvString = event.target.result;

                // Use PapaParse to parse the CSV file line by line
                Papa.parse(csvString, {
                    // step: (result, parser) => {
                    step: (result) => {
                        countRecordLines++;
                        // Result.data contains the parsed line
                        if (countRecordLines === 2) {

                            numberOfAnswersForQuestion = parseInt(result.data[1]);

                            let validAnswer = !(result.data[3] === "FALSE" || result.data[3] === "false");

                            updatedTestData = {
                                ...testData,
                                testName: result.data[0],
                                numberOfAnswersForQuestion: parseInt(result.data[1]),
                                maxNumberOfValidAnswers: parseInt(result.data[2]),
                                admitQuestionsWithoutValidAnswer: validAnswer
                            };
                            setTestData(updatedTestData);
                        }

                        if (countRecordLines >= 5 && result.data.toString().length > 0) {

                            const CSVQuestionNumberOfAnswers = (result.data.length - 1) / 2;
                            //TODO: treat the incompleta data case
                            if (updatedTestData.numberOfAnswersForQuestion !== CSVQuestionNumberOfAnswers) {
                                console.log("Incomplete data on question " + (countRecordLines - 4));
                                console.log("CSVQuestionNumberOfAnswers: " + CSVQuestionNumberOfAnswers);
                                console.log("testData.numberOfAnswersForQuestion: " + updatedTestData.numberOfAnswersForQuestion)
                            }

                            questionsList.push(result.data[0]);

                            for (let i = 0; i < updatedTestData.numberOfAnswersForQuestion; i++) {
                                answersList.push(result.data[i * 2 + 1]);
                                validAnswerList.push(result.data[i * 2 + 2]);
                            }
                        }
                    },
                    complete: () => {

                        const updatedAnswers = [];
                        for (let i = 0; i < questionsList.length; i++) {
                            let answersForCurrentQuestion = [];
                            for (let j = 0; j < numberOfAnswersForQuestion; j++) {
                                const answerIndex = i * numberOfAnswersForQuestion + j;

                                answersForCurrentQuestion.push(
                                    {
                                        answer: answersList[answerIndex],
                                        rightAnswer: validAnswerList[answerIndex]
                                    }
                                )

                            }
                            updatedAnswers.push(answersForCurrentQuestion);
                        }

                        const updatedQuestions = [];
                        for (let i = 0; i < questionsList.length; i++) {
                            updatedQuestions.push(
                                {
                                    question: questionsList[i],
                                    answers: updatedAnswers[i]
                                }
                            )
                            console.log("updated questions [" + i + "] :" + updatedQuestions[i].question)
                        }

                        updatedTestData.questions = updatedQuestions;
                        updatedTestData.numberOfQuestions = questionsList.length;
                        setTestData(updatedTestData);


                        // Parsing complete
                        console.log('Parsing complete');
                    },
                    header: false, // Set to true if your CSV file has a header row
                });
            };
            reader.readAsText(file);
        }
        window.location.href = `/tests/csv/show-import`;
    };


    return (
        <div className="bg-local">
            <div className="h-screen bg-cover bg-center "
                 style={{
                     backgroundImage: `url(${img})`,
                 }}>
                <div className="h-screen">
                    <div className="mt-18 flex items-center justify-center text-center">

                        <div className="mt-18 backdrop-blur-lg h-46 rounded-xl">

                            <label
                                className="block mb-2 text-lg rounded-md px-4 py-2 font-medium text-white dark:text-white"
                                htmlFor="file_input">{messages.CLICK_THE_BUTTON}
                            </label>

                            <div className="mt-10 hover:-translate-y-0.5 transition-all duration-500 ">
                                <input
                                    className="block w-full text-sm text-gray-900
                                        border border-gray-300 rounded-lg cursor-pointer
                                        bg-gray-50 dark:text-gray-400
                                        focus:outline-none dark:bg-gray-700
                                        dark:border-gray-600 dark:placeholder-gray-400"
                                    aria-describedby="file_input_help"
                                    id="file_input"
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{display: 'none'}} // Hide the input visually
                                />

                                {/*Your custom-styled button*/}
                                <label
                                    htmlFor="file_input"
                                    className="w-2/12 bg-blue-500 hover:bg-orange-500 text-xl text-white font-bold py-4 px-8 rounded-md transition-all duration-500">
                                    {messages.CHOOSE_FILE}
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )

}


