
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import './App.css';
import NoPageFound from "./pages/NoPageFound";
import AllTestsPage from "./pages/AllTestsPage"
import SingleTestPage from "./pages/SingleTestPage"
import LandingPage from "./pages/LandingPage"
import DeleteTestPage from "./pages/DeleteTestPage";
import Navbar from "./components/Navbar";
import AddTestPage from "./pages/AddTestPage";
import UpdateTestPage from "./pages/UpdateTestPage";
import UpdateTest from "./components/UpdateTest";
import ImportTestPage from "./pages/ImportTestPage";
import ShowImportTestPage from "./components/ShowImportTestPage";
import ExportTestPage from "./pages/ExportTestPage";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SignInPage";
import SignOutPage from "./pages/SignOutPage";
import StudentTestingPage from "./pages/StudentTestingPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import AllTestsByUserIdPage from "./pages/AllTestsByUserIdPage";
import UserPage from "./pages/UserPage";
import TimeToLogIn from "./components/TimeToLogIn";

function App() {
  return (
      <div className="Container">
        <BrowserRouter>
          <Navbar/>
          <Routes>
            <Route path="/" element={<LandingPage/>}/>
            <Route path="/admin/tests" element={<AllTestsPage/>}/>
            <Route path="/tests/user" element={<AllTestsByUserIdPage/>}/>
            <Route path="/tests/:id" element={<SingleTestPage/>}/>
            <Route path="/tests/:id/add" element={<AddTestPage/>}/>
            <Route path="/tests/delete" element={<DeleteTestPage/>}/>
            <Route path="/tests/update" element={<UpdateTestPage/>}/>
            <Route path="/tests/:testID/update" element={<UpdateTest/>}/>
            <Route path="/tests/csv/import" element={<ImportTestPage/>}/>
            <Route path="/tests/csv/show-import" element={<ShowImportTestPage/>}/>
            <Route path="/tests/csv/export" element={<ExportTestPage/>}/>
            <Route path="/auth/register" element={<SignUpPage/>}/>
            <Route path="/auth/login" element={<SignInPage/>}/>
            <Route path="/auth/logout" element={<SignOutPage/>}/>
            <Route path="/auth/logout" element={<StudentTestingPage/>}/>
            <Route path="/auth/terms-of-service" element={<TermsOfServicePage/>}/>
            <Route path="/auth/privacy-policy" element={<PrivacyPolicyPage/>}/>
            <Route path="/auth/forgot-password" element={<ForgotPasswordPage/>}/>
            <Route path="/auth/privacy-policy" element={<PrivacyPolicyPage/>}/>
            <Route path="/users/user-id/:id" element={<UserPage/>}/>
            <Route path="/users/time-to-log-in" element={<TimeToLogIn/>}/>

            <Route path="*" element={<NoPageFound/>}/>
          </Routes>
          {/*<Footer/>*/}
        </BrowserRouter>
      </div>
  );
}

export default App;
