import React from "react";


import img1 from '../images/1.jpg';
import img2 from '../images/2.jpg';
import img3 from '../images/3.jpg';
import img4 from '../images/4.jpg';
// import img5 from '../images/5.jpg';
// let img1 = "https://storage.googleapis.com/images_tst_now/images/1.png";


const accountListMenuItems = [
    {
        src: img1,
        alt: "image 1",
    },
    {
        src: img2,
        alt: "image 2",
    },
    {
        src: img3,
        alt: "image 3",
    },
    {
        src: img4,
        alt: "image 4",
    },
]


export const imagesList = accountListMenuItems.map(({src, alt}, index) => (
    <img
        key={index}
        src={src}
        alt={alt}
        className="h-full w-full object-cover"
    />));

