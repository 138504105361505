import React from "react";
import DeleteTest from "../components/DeleteTest";

export default function DeleteTestPage() {

    return (
        <div className="single_test">
            <DeleteTest/>
        </div>
    )
}
