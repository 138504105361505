import React, {useEffect, useState} from "react";
import axios from 'axios';

import img from '../images/1.jpg';
import {Link} from "react-router-dom";
import {LOCAL_URL, SignInPage_EN, SignInPage_RO} from "../constants/constants";
import MessageRed from "../components/MessageRed";
// import {checkLogin} from "../components/Util";
import {useLanguage} from "../LanguageContext";


export default function SignInPage() {

    const url = `${LOCAL_URL}/api/auth/login`;

    const [loginData, setLoginData] = useState({
        email: "",
        password: ""
    });

    const [redMessage, setRedMessage] = useState("")
    const [showRedMessage, setShowRedMessage] = useState(false)

    const [messages, setMessages] = useState(SignInPage_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(SignInPage_EN);
        } else if (language === 'ro') {
            setMessages(SignInPage_RO);
        }
    }, [language])

    // useEffect(() => {
    //     checkLogin();
    // }, []);

    // const loggedUserId = checkLogin();

    async function submitAction(e) {
        e.preventDefault();
        // sessionStorage.clear();
        sessionStorage.removeItem("JWT");
        sessionStorage.removeItem("userId");
        await axios.post(url, loginData)
            .then(response => {
                if (response.data.userId !== null) {
                    setShowRedMessage(false);
                    const token = response.data.jwt;
                    // console.log("JWT Token: " + token);
                    sessionStorage.setItem("JWT", token);
                    const userId = response.data.userId;
                    // console.log("userId: " + userId);
                    sessionStorage.setItem("userId", userId)
                    window.location.href = `/tests/user`;
                } else {
                    setRedMessage(messages.INCORRECT_EMAIL_OR_PASSWORD)
                    setShowRedMessage(true);
                    setTimeout(() => {
                        setLoginData({email: "", password: ""});
                        setShowRedMessage(false);
                    }, 2000);
                }
            })
            .catch(error => {
                console.error(error);
                setRedMessage(messages.ERROR_GETTING_DATA_FOR_LOGGED_USER + error.message);
                setShowRedMessage(true);
            })
    }


    return (
        <div className="mt-20 sm:mt-0">
            <div className="h-screen flex mx-5">
                <div className="hidden lg:flex w-full lg:w-1/2 justify-around items-center px-8 md:px-12 lg:px-14">
                    <div className="bg-cover bg-center w-full rounded-md shadow-2xl p-5"
                         style={{
                             backgroundImage: `url(${img})`,
                             height: "40%",
                         }}>
                    </div>
                </div>
                <div className="flex w-full lg:w-1/2 justify-center items-center bg-white space-y-8">
                    <div className="w-full px-8 md:px-12 lg:px-14">
                        <form className="bg-white rounded-md shadow-2xl p-5" onSubmit={submitAction}>
                            <h1 className="text-gray-800 font-bold text-2xl mb-1">{messages.HELLO_AGAIN}</h1>
                            <p className="text-sm font-normal text-gray-600 mb-7">{messages.WELCOME_BACK}</p>
                            <div className="flex items-center border-2 mb-5 py-2 px-3 rounded-2xl">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9
                                          9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"/>
                                </svg>
                                <input id="email" className=" pl-2 w-full outline-none border-none" type="email"
                                       name="email"
                                       placeholder={messages.EMAIL_ADDRESS}
                                       value={loginData.email}
                                       onChange={(e) => {
                                           const updatedLoginData = {...loginData, email: e.target.value}
                                           setLoginData(updatedLoginData)
                                           setShowRedMessage(false);
                                       }}/>
                            </div>
                            <div className="flex items-center border-2 mb-4 py-2 px-3 rounded-2xl ">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400"
                                     viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0
                                          012-2zm8-2v2H7V7a3 3 0 016 0z"
                                          clipRule="evenodd"/>
                                </svg>
                                <input className="pl-2 w-full outline-none border-none" type="password" name="password"
                                       id="password"
                                       placeholder={messages.PASSWORD}
                                       value={loginData.password}
                                       onChange={e => {
                                           const updatedLoginData = {...loginData, password: e.target.value};
                                           setLoginData(updatedLoginData);
                                           setShowRedMessage(false);
                                       }}/>

                            </div>
                            <div className="w-full items-center rounded-3xl">
                                {showRedMessage ? (<div>
                                    <MessageRed message={redMessage}/>
                                </div>) : (<div className="w-full items-center rounded-3xl text-white">
                                    <p>.</p>
                                </div>)}
                            </div>
                            <button type="submit"
                                    className="block w-full mt-3 py-2 rounded-lg bg-orange-600 hover:bg-blue-500
                                    hover:-translate-y-0.5 transition-all duration-500 text-white font-semibold mb-2">
                                {messages.LOG_IN}
                            </button>
                            <div className="flex justify-between mt-4">
                                <div className="
                                mt-2
                                text-sm
                                hover:mt-0 hover:text-blue-700 hover:font-bold hover:text-lg
                                cursor-pointer
                                duration-500
                                transition-all">
                                    <Link to="/auth/forgot-password">
                                        {messages.FORGOT_PASSWORD}
                                    </Link>
                                </div>

                                <div
                                    className="text-sm ml-2 mt-2
                                hover:mt-0 hover:text-blue-500 hover:font-bold hover:text-lg
                                cursor-pointer duration-500 transition-all">
                                    <Link to="/auth/register">
                                        {messages.DON_T_HAVE_AN_ACCOUNT}
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}



