import {
    AcademicCapIcon,
    ChevronDownIcon,
    DocumentArrowDownIcon,
    DocumentArrowUpIcon,
    DocumentMinusIcon,
    DocumentPlusIcon,
    PencilIcon,
    RectangleStackIcon
} from "@heroicons/react/24/outline";
import React, {createElement, useEffect, useMemo, useState} from "react";
import {Collapse, ListItem, Menu, MenuHandler, MenuItem, MenuList, Typography} from "@material-tailwind/react";
import {COLORS, TeacherAccountMenu_EN, TeacherAccountMenu_RO} from "../constants/constants";
import {checkLogin} from "./Util";
import {useLanguage} from "../LanguageContext";


export default function TeacherAccountMenu() {

    const {language} = useLanguage();
    const [messages, setMessages] = useState(TeacherAccountMenu_EN);

    useEffect(() => {
        if (language === 'en') {
            setMessages(TeacherAccountMenu_EN);
        } else if (language === 'ro') {
            setMessages(TeacherAccountMenu_RO);
        }
    }, [language])


    const loggedUserId = useMemo(() => {
        return checkLogin();
    }, []);


    const accountListMenuItems = [
        {
            color: "blue",
            icon: RectangleStackIcon,
            title: messages.SHOW_ALL_TESTS,
            description: messages.SHOW_ALL_TESTS_DESCR,
            href: `/tests/user`,
        },
        {
            color: "orange",
            icon: DocumentPlusIcon,
            title: messages.ADD_NEW_TEST,
            description: messages.ADD_NEW_TEST_DESCR,
            href: `/tests/${loggedUserId}/add`,
        },
        {
            color: "purple",
            icon: DocumentArrowUpIcon,
            title: messages.EXPORT_TEST,
            description: messages.EXPORT_TEST_DESCR,
            href: "/tests/csv/export",
        },
        {
            color: "cyan",
            icon: DocumentArrowDownIcon,
            title: messages.IMPORT_TEST,
            description: messages.IMPORT_TEST_DESCR,
            href: "/tests/csv/import"
        },
        {
            color: "teal",
            icon: PencilIcon,
            title: messages.UPDATE_TEST,
            description: messages.UPDATE_TEST_DESCR,
            href: "/tests/update",
        },
        {
            color: "red",
            icon: DocumentMinusIcon,
            title: messages.DELETE_TEST,
            description: messages.DELETE_TEST_DESCR,
            href: "/tests/delete"
        },
    ];

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const renderItems = accountListMenuItems.map(
        ({icon, title, description, color, href}, key) => (
            <a href={href} key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg">
                    <div className={`rounded-lg p-5 ${COLORS[color]}`}>
                        {createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 w-6",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="flex items-center text-sm"
                        >
                            {title}
                        </Typography>
                        <Typography variant="small" color="gray" className="font-normal">
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </a>
        )
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{mainAxis: 20}}
                placement="bottom"
                allowHover={true}
            >
                <MenuHandler>
                    <Typography as="div" variant="small"
                                className="font-normal hover:-translate-y-0.5 transition-all duration-500">
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4"
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            <AcademicCapIcon className="h-[18px] w-[18px]"/>
                            {messages.TEACHER_ACCOUNT}
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <ul className="grid grid-cols-4 gap-y-2">{renderItems}</ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}
