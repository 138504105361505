import React, {useEffect, useState} from "react";
import {LOCAL_URL, UserPage_EN, UserPage_RO} from "../constants/constants";
import axios from "axios";
import MessageRed from "../components/MessageRed";
import MessageGreen from "../components/MessageGreen";
import {useLanguage} from "../LanguageContext";
import {wait} from "@testing-library/user-event/dist/utils";


export default function UserPage() {

    const [loggedClientData, setLoggedClientData] = useState({
        name: '', surname: '', email: '', authority: []
    });

    const [passwords, setPasswords] = useState({
        currentPassword: '', newPassword: ''
    })

    const [changePassword, setChangePassword] = useState(false);

    const clientUrl = `${LOCAL_URL}/api/users/user-id/`;

    const passwordUpdateUrl = `${LOCAL_URL}/api/users/update/`

    const [showBadData, setShowBadData] = useState(false);

    const [redMessage, setRedMessage] = useState("");

    const [greenMessage, setGreenMessage] = useState("");

    const userId = sessionStorage.getItem('userId');

    const [messages, setMessages] = useState(UserPage_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(UserPage_EN);
        } else if (language === 'ro') {
            setMessages(UserPage_RO);
        }
    }, [language])


    useEffect(() => {
        axios.get(`${clientUrl}${userId}`)
            .then(response => {
                const authoritiesListOfObjects = response.data.authorities;
                const updatedAuthorities = [];

                if (authoritiesListOfObjects && authoritiesListOfObjects.length > 0) {
                    authoritiesListOfObjects.forEach((object) => {
                        updatedAuthorities.push(object.authority)
                    });
                    setLoggedClientData({
                        name: response.data.name,
                        surname: response.data.surname,
                        email: response.data.emailAddress,
                        authority: updatedAuthorities
                    })
                }
            })
            .catch(e => {
                if (e.message.includes('status code 401')) {
                    window.location.href = "/users/time-to-log-in";
                }
            });
    }, [clientUrl, userId]);


    function formatAuthorities() {
        const newFromLoggedClientData = loggedClientData.authority.join(' & ');
        const teacherReplaced = newFromLoggedClientData.replace(/TEACHER/g, messages.TESTS_OWNER);
        const adminReplaced = teacherReplaced.replace(/ADMIN/g, messages.ADMINISTRATOR);
        const studentReplaced = adminReplaced.replace(/STUDENT/g, messages.CANDIDATE_FOR_TESTING)
        return studentReplaced;
    }

    function handleWantToChangePasswordClick() {
        setChangePassword(true);
    }


    function handleChangePasswordClick() {

        let readyToUpdate = true;


        if (!checkPassword(passwords.currentPassword, messages.CURRENT_PASSWORD
            , passwords.newPassword, messages.NEW_PASSWORD)) {

            readyToUpdate = false;
            return;
        }

        if (readyToUpdate) {
            axios.patch(`${passwordUpdateUrl}${userId}`, {
                currentPassword: passwords.currentPassword, newPassword: passwords.newPassword
            })
                .then(response => {
                    console.log(messages.PASSWORD_UPDATED);
                    setGreenMessage(messages.PASSWORD_UPDATED);
                    wait(2000).then(() => {setGreenMessage('')});
                    setRedMessage('');
                    setShowBadData(false);
                    setPasswords({
                        currentPassword: '',
                        newPassword: ''
                    });
                    setChangePassword(false);
                })
                .catch(e => {
                    console.log(messages.ERROR_PATCHING_NEW_PASSWORD);
                    setRedMessage(messages.ERROR_PATCHING_NEW_PASSWORD);
                    setShowBadData(true);
                });
        }
    }


    const checkPassword = (currentPassword, label1, newPassword, label2) => {
        if (currentPassword.length < 5) {
            setShowBadData(true);
            setRedMessage(messages.PASSWORD_TOO_SMALL + label1);
            return false;
        } else if (newPassword.length < 5) {
            setShowBadData(true);
            setRedMessage(messages.PASSWORD_TOO_SMALL + label2);
            return false;
        } else {
            setShowBadData(false);
            setGreenMessage(messages.PASSWORD_LENGTH_OK);
            return true;
        }
    }


    function handleLogoutClick() {
        sessionStorage.clear();
        window.location.href = "/auth/login";
    }


    return (
        <div className="mt-10 sign-up-page">
            <div className="bg-blue-gray-50 min-h-screen flex flex-col">

                <div className="container mb-5 max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">

                    <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">

                        <h1 className="mb-2 font-bold text-3xl text-center text-indigo-800">
                            {messages.USER_GREETING + loggedClientData.name || "User"},</h1>
                        <div>
                            {!changePassword && (<h3 className="mb-5 font-bold text-md text-center text-indigo-800">
                                {messages.SUBTITLE_1}</h3>)}
                        </div>

                        <div>
                            {changePassword && (<h3 className="mb-5 font-bold text-md text-center text-indigo-800">
                                {messages.SUBTITLE_2}</h3>)}
                        </div>

                        <div>
                            {!changePassword && (
                                <div className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4">
                                    {loggedClientData.name || messages.NAME}
                                </div>)}
                        </div>

                        <div>
                            {!changePassword && (
                                <div className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4">
                                    {loggedClientData.surname || messages.SURNAME}
                                </div>)}
                        </div>

                        <div className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4">
                            {loggedClientData.email || messages.EMAIL}
                        </div>

                        <div>
                            {!changePassword && (
                                <div className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4">
                                    {(loggedClientData.authority !== [] && formatAuthorities()) || messages.USER_ROLE}
                                </div>)}
                        </div>

                        <form>
                            <div>
                                {changePassword && (<input
                                    type="password"
                                    className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4"
                                    name="current_password"
                                    placeholder={messages.CURRENT_PASSWORD}
                                    onChange={(e) => setPasswords({...passwords, currentPassword: e.target.value})}
                                />)}
                            </div>

                            <div>
                                {changePassword && (<input
                                    type="password"
                                    className="block border font-bold border-blue-gray-100 w-full p-3 rounded mb-4"
                                    name="new_password"
                                    placeholder={messages.NEW_PASSWORD}
                                    onChange={(e) => setPasswords({...passwords, newPassword: e.target.value})}
                                />)}
                            </div>
                        </form>

                        <div className="mb-4">
                            {showBadData ? (<div>
                                <MessageRed message={redMessage}/>
                            </div>) : (<div>
                                <MessageGreen message={greenMessage}/>
                            </div>)}
                        </div>

                        <div>
                            {!changePassword && (<button
                                // type="submit"
                                className="w-full text-center py-3 rounded-lg bg-green-500 text-white
                                        hover:bg-green-700 transition-all duration-500 focus:outline-none mb-4"
                                onClick={() => handleWantToChangePasswordClick()}
                            >
                                {messages.CHANGE_PASSWORD}
                            </button>)}
                        </div>

                        <div>
                            {changePassword && (<button
                                type="submit"
                                className="w-full text-center py-3 rounded-lg bg-green-500 text-white
                                        hover:bg-green-700 transition-all duration-500 focus:outline-none mb-4"
                                onClick={() => handleChangePasswordClick()}
                            >
                                {messages.SAVE_NEW_PASSWORD}
                            </button>)}
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="w-full text-center py-3 rounded-lg bg-orange-500 text-white
                                        hover:bg-orange-700 transition-all duration-500 focus:outline-none"
                                onClick={() => handleLogoutClick()}
                            >
                                {messages.LOGOUT_BUTTON}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>)

}
