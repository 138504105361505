import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from 'axios';

import {
    ACTION_BUTTON,
    LOCAL_URL,
    QUESTION_MAX_NO_OF_ANSWERS,
    QUESTION_MIN_NO_OF_ANSWERS,
    QUESTION_MIN_NO_OF_VALID_ANSWERS,
    SINGLE_TEST_BODY1,
    SINGLE_TEST_BODY2,
    SINGLE_TEST_HEAD1,
    SINGLE_TEST_HEAD2,
    TEST_CONTENT_ANSWER_BODY1,
    TEST_CONTENT_ANSWER_BODY2,
    TEST_CONTENT_ANSWER_HEAD1,
    TEST_CONTENT_ANSWER_HEAD2,
    TEST_CONTENT_QUESTION_BODY1,
    TEST_CONTENT_QUESTION_BODY2,
    TEST_CONTENT_QUESTION_HEAD1,
    TEST_CONTENT_QUESTION_HEAD2,
    TEST_MAX_NO_OF_QUESTIONS,
    TEST_MIN_NO_OF_QUESTIONS,
    TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS,
    UpdateTest_EN,
    UpdateTest_RO
} from "../constants/constants";
import {Button,} from "@material-tailwind/react";
import MessageRed from "./MessageRed";
import MessageGreen from "./MessageGreen";
import {checkLogin} from "./Util";
import {useLanguage} from "../LanguageContext";


export default function UpdateTest() {

    const {testID} = useParams();

    const [testData, setTestData] = useState([]);

    const url = `${LOCAL_URL}/api/tests/${testID}`;

    let userData = {};

    let [redMessage, setRedMessage] = useState("")
    let [showBadData, setShowBadData] = useState(false);
    let [showNetworkError, setShowNetworkError] = useState(false);

    let [valueForAdmitQuestionsWithoutValidAnswer, setValueForAdmitQuestionsWithoutValidAnswer] = useState("");

    const [dataChecked, setDataChecked] = useState(false);
    const [renderComponent, setRenderComponent] = useState(false);
    const [initialTestName, setInitialTestName] = useState("")

    const fragments = useState([]);

    let testUserId = null;

    const loggedUserId = checkLogin();

    const [messages, setMessages] = useState(UpdateTest_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(UpdateTest_EN);
        } else if (language === 'ro') {
            setMessages(UpdateTest_RO);
        }
    }, [language])


    useEffect(() => {
        axios.get(url)
            .then(response => {
                const getTest = response.data;
                setTestData(getTest);
            })
            .catch(error => {
                console.error(messages.ERROR_FETCHING_DATA, error);
                setRedMessage(messages.ERROR_FETCHING_DATA + error.message);
                setShowNetworkError(true);
            });
    }, [messages.ERROR_FETCHING_DATA, url]);


    if (!renderComponent) {
        checkLogin();
        axios.get(url)
            .then(response => {
                const getTest = response.data;
                setTestData(getTest);
                setRenderComponent(true)
                setInitialTestName(getTest.testName);
            })
            .catch(error => {
                console.error(messages.ERROR_FETCHING_DATA, error);
                setRedMessage(messages.ERROR_FETCHING_DATA + error.message);
                setShowNetworkError(true);
            });
    }


    function checkInputData() {
        if (
            testData.testName
            && testData.numberOfQuestions
            && testData.numberOfAnswersForQuestion
            && testData.maxNumberOfValidAnswers

        ) {
            return true;
        } else {
            setShowBadData(true);
            setRedMessage(messages.PLEASE_COMPLETE_ALL_DATA);
            return false;
        }
    }


    function checkMinNoOfQuestions() {
        if (
            testData.numberOfQuestions >= TEST_MIN_NO_OF_QUESTIONS
        ) {
            return true;
        } else {
            setShowBadData(true);
            setRedMessage(messages.NO_OF_Q_MUST_NOT_BE_SMALLER + TEST_MIN_NO_OF_QUESTIONS);
            return false;
        }
    }


    function checkMaxNoOfQuestions() {
        if (
            testData.numberOfQuestions <= TEST_MAX_NO_OF_QUESTIONS
        ) {
            return true;
        } else {
            setRedMessage(messages.NO_OF_Q_MUST_NOT_BE_GREATER + TEST_MAX_NO_OF_QUESTIONS);
            return false;
        }
    }


    function checkMinNoOfAnswers() {
        if (
            testData.numberOfAnswersForQuestion >= QUESTION_MIN_NO_OF_ANSWERS
        ) {
            return true;
        } else {
            setRedMessage(messages.NO_OF_A_MUST_NOT_BE_SMALLER + TEST_MIN_NO_OF_QUESTIONS);
            return false;
        }
    }


    function checkMaxNoOfAnswers() {
        if (
            testData.numberOfAnswersForQuestion <= QUESTION_MAX_NO_OF_ANSWERS
        ) {
            return true;
        } else {
            setRedMessage(messages.NO_OF_A_MUST_NOT_BE_GREATER + QUESTION_MAX_NO_OF_ANSWERS);
            return false;
        }
    }


    function checkNumberOfValidAnswers() {
        if (
            testData.admitQuestionsWithoutValidAnswer === false
        ) {
            if (
                testData.maxNumberOfValidAnswers >= QUESTION_MIN_NO_OF_VALID_ANSWERS
                && checkMaxNumberOfValidAnswers()
            ) {
                return true;
            } else {
                setRedMessage(messages.NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN + QUESTION_MIN_NO_OF_VALID_ANSWERS);
                return false;
            }
        } else if (
            testData.admitQuestionsWithoutValidAnswer === true
        ) {
            if (
                testData.maxNumberOfValidAnswers >= TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS
                && checkMaxNumberOfValidAnswers()) {
                return true;
            } else {
                setRedMessage(messages.NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN + TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS);
                return false;
            }
        }
    }


    function checkMaxNumberOfValidAnswers() {
        if (
            testData.maxNumberOfValidAnswers <= testData.numberOfAnswersForQuestion // 1 <= 3
        ) {
            return true;
        } else {
            setRedMessage(messages.NO_OF_VALID_ANSWERS_NOT_GREATER_THAN + testData.numberOfAnswersForQuestion);
            return false;
        }
    }


    async function checkForTestNameHasNoDuplicates() {

        await axios.get(`${LOCAL_URL}/api/users/get-test-owner/${testData.testId}`)
            .then(response => {
                userData = response.data;
                testUserId = response.data.userId;
                checkTestUserIdMatchesLoggedUserId();
            })
            .catch(error => {
                console.error(messages.ERROR_FETCHING_DATA, error)
                setRedMessage(messages.ERROR_FETCHING_DATA + error.message);
                setShowNetworkError(true);
            })


        for (let i = 0; i < userData.setOfTests.length; i++) {
            const testName = userData.setOfTests[i].testName;
            if (
                testData.testName === testName && testData.testName !== initialTestName
            ) {
                setRedMessage(messages.THE_TEST_NAME_ALREADY_USED);
                return false
            }
        }
        return true;
    }

    function checkTestUserIdMatchesLoggedUserId() {
        if (testUserId !== loggedUserId) {
            console.log(messages.USER_NOT_MATCH)
            setRedMessage(messages.USER_NOT_MATCH);
            setShowNetworkError(true);
            return false
        }
        return true;
    }


    function checkAllAnswersHaveQuestion() {

        const filteredOfNullQuestions = testData.questions
            .filter(
                element => !!element.question
            );

        let countNotNullAnswers = 0;
        for (let i = 0; i < testData.questions.length; i++) {
            let filteredOfNullAnswersForOneQuestion = testData.questions[i].answers
                .filter(
                    element => !!element.answer
                    // element => element.answer !== ""
                    // && element.answer !== undefined
                    // && element.answer !== null
                ).length;
            countNotNullAnswers += filteredOfNullAnswersForOneQuestion;
        }

        if (
            filteredOfNullQuestions.length < testData.numberOfQuestions
            || countNotNullAnswers < testData.numberOfQuestions * testData.numberOfAnswersForQuestion
        ) {
            setRedMessage(messages.PLEASE_INPUT_ALL_DATA);
            return false;
        }
        return true;
    }

    function checkQuestionNoOfValidAnswers() {

        let sortedQuestions = testData.questions.sort((a, b) => a.questionId - b.questionId)

        for (let i = 0; i < testData.questions.length; i++) {
            let noOfQuestionRightAnswers = sortedQuestions[i].answers.filter(answer => answer.rightAnswer === true).length;

            if (noOfQuestionRightAnswers > testData.maxNumberOfValidAnswers
            ) {
                setRedMessage(messages.INVALID_NO_OF_RIGHT_ANSWERS + `${i + 1}!`);
                return false;
            } else if (
                noOfQuestionRightAnswers <= testData.maxNumberOfValidAnswers

            ) {
                if (
                    testData.admitQuestionsWithoutValidAnswer === true
                ) {
                    if (noOfQuestionRightAnswers < 0) {
                        setRedMessage(messages.NO_OF_RIGHT_ANSWERS_BETWEEN_0_AND
                            + testData.maxNumberOfValidAnswers + messages.FOR_QUESTION + `${i + 1}!`);
                        return false;
                    }
                } else if (testData.admitQuestionsWithoutValidAnswer === false) {
                    if (noOfQuestionRightAnswers < 1) {
                        if (testData.maxNumberOfValidAnswers === 1) {
                            setRedMessage(messages.NO_OF_RA_SHOULD_BE_1 + `${i + 1}!`);
                        } else {
                            setRedMessage(messages.NO_OF_RA_SHOULD_BE_BTW_1_AND
                                + testData.maxNumberOfValidAnswers + messages.FOR_QUESTION + `${i + 1}!`);
                        }
                        return false;
                    }
                }
            }
        }
        return true;
    }


    useEffect(() => {
        if (
            testData.admitQuestionsWithoutValidAnswer
        ) {
            setValueForAdmitQuestionsWithoutValidAnswer(messages.YES);
        } else {
            setValueForAdmitQuestionsWithoutValidAnswer(messages.NO);
        }
    }, [messages.NO, messages.YES, testData.admitQuestionsWithoutValidAnswer]);


    const handleChangeTestName = (event) => {
        let inputValue = event.target.value;

        const updatedTestData = {...testData, testName: inputValue};

        setTestData(updatedTestData);
    };


    async function updateTest(e) {
        e.preventDefault();

        if (
            checkInputData()
            && checkMinNoOfQuestions()
            && checkMaxNoOfQuestions()
            && checkMinNoOfAnswers()
            && checkMaxNoOfAnswers()
            && checkNumberOfValidAnswers()
            && checkAllAnswersHaveQuestion()
            && checkQuestionNoOfValidAnswers()
            && await checkForTestNameHasNoDuplicates()
        ) {

            setShowBadData(false);
            setDataChecked(true)

            let testId = testData.testId;

            await axios.patch(`${LOCAL_URL}/api/tests/${testId}`, testData)
                .then(response => {
                    // Handle the response, e.g., show a success message
                    setTimeout(() => {
                        window.location.href = `/tests/${testId}`;
                    }, 1000);
                })
                .catch(error => {
                    // Handle errors, e.g., show an error message
                    console.error('Error updating object:', error);
                    setRedMessage(messages.ERROR_UPDATING_OBJECT + error.message);
                    setShowBadData(true);
                });
            // show the edited test
            // window.location.href = `/tests/${testId}`;
        } else {
            setShowBadData(true);
        }
    }


    const writeTestStructure = () => {

        let sortedQuestions = testData.questions?.sort((a, b) => a.questionId - b.questionId);

        for (let i = 0; i < testData.numberOfQuestions; i++) {
            const questionRows = [];
            const answerRows = [];
            questionRows.push(
                <tr id="question" className="w-full border-collapse" key={i + 100}>
                    <td className={`${TEST_CONTENT_QUESTION_BODY1}`}>{i + 1}</td>
                    <td className={`${TEST_CONTENT_QUESTION_BODY2}`}>
                        <input
                            type="text"
                            className="form-control w-full"
                            value={sortedQuestions[i].question === undefined ? "" : sortedQuestions[i].question}
                            onChange={(e) => {
                                const updatedQuestions = [...sortedQuestions];
                                updatedQuestions[i].question = e.target.value;
                                const updatedTestData = {...testData, questions: updatedQuestions};
                                setTestData(updatedTestData);
                                sortedQuestions = [...updatedQuestions];
                            }}
                        />
                    </td>
                </tr>
            );


            const sortedAnswers = sortedQuestions[i].answers.sort((a, b) => a.answerId - b.answerId);

            for (let j = 0; j < testData.numberOfAnswersForQuestion; j++) {

                answerRows.push(
                    <tr id="answer" className="w-full border-collapse" key={j + 200}>

                        <td className={TEST_CONTENT_ANSWER_BODY1}>{j + 1}</td>
                        <td className={TEST_CONTENT_ANSWER_BODY2}>
                            <input
                                type="text"
                                className="form-control w-full"
                                value={sortedAnswers[j].answer === undefined ? "" : sortedAnswers[j].answer}
                                onChange={(e) => {
                                    const updatedAnswers = [...sortedAnswers];
                                    updatedAnswers[j].answer = e.target.value;
                                    sortedQuestions[i].answers = updatedAnswers;
                                    const updatedTestData = {...testData, questions: sortedQuestions};
                                    setTestData(updatedTestData);
                                }}
                            />
                        </td>

                        <td className={TEST_CONTENT_ANSWER_BODY1}>
                            <div className="form-control flex items-center justify-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox w-full h-4 "
                                    checked={sortedAnswers[j].rightAnswer}
                                    onChange={(event) => {
                                        const updatedAnswers = [...sortedAnswers];
                                        updatedAnswers[j].rightAnswer = event.target.checked;
                                        sortedQuestions[i].answers = updatedAnswers;
                                        const updatedTestData = {...testData, questions: sortedQuestions};
                                        setTestData(updatedTestData);
                                    }}
                                />
                            </div>
                        </td>

                    </tr>
                )
            }


            fragments.push(
                <React.Fragment>
                    <div
                        className="h-full w-full overflow-y-auto justify-center items-center mt-2
                                    border-separate border-blue-400 border-2 rounded-lg">

                        <table
                            className="w-full table-auto mx-auto max-w-screen-3xl px-2 py-2
                                        rounded-lg bg-green-50">

                            <thead>
                            <tr className="w-full border-collapse">
                                <th className={`${TEST_CONTENT_QUESTION_HEAD1}`}>{messages.QUESTION_NO}</th>
                                <th className={`${TEST_CONTENT_QUESTION_HEAD2}`}>{messages.QUESTION}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {questionRows}
                            </tbody>

                        </table>

                        <table
                            className="w-full table-auto mx-auto max-w-screen-32xl px-2 py-2
                                            rounded-lg bg-yellow-50">

                            <thead>
                            <tr className="w-full border-collapse">
                                <th className={`${TEST_CONTENT_ANSWER_HEAD1}`}>{messages.ANSWER_NO}</th>
                                <th className={`${TEST_CONTENT_ANSWER_HEAD2}`}>{messages.ANSWER}</th>
                                <th className={`${TEST_CONTENT_ANSWER_HEAD1}`}>{messages.RIGHT_ANSWER}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {answerRows}
                            </tbody>

                        </table>

                    </div>
                </React.Fragment>
            );
        }
        return fragments;
    }


    const populateInner = () => {
        return renderComponent && writeTestStructure().map((item, index) =>
            <div key={index}>
                {item}
            </div>
        )
    }

    return (
        <div className="mt-16">
            <form className="mx-auto my-auto col-md-6 ">
                <div className="flex flex-col">
                    <div className="space-y-1 h-screen">
                        <div className=" mt-2 z-40 w-full">
                            <div className="overflow-hidden">
                                <table className="table-auto mx-auto max-w-screen-3xl px-2 py-2
                                            border-separate border-blue-400 border-4 rounded-lg
                                            bg-cyan-50 "
                                >

                                    <thead>
                                    <tr className="w-full border-collapse">
                                        <th className={`${SINGLE_TEST_HEAD1}`}>
                                            <strong>{messages.UPDATE_TEST}</strong><br/>{messages.TEST_NAME}</th>
                                        <th className={`${SINGLE_TEST_HEAD2}`}>{messages.NO_OF_QUESTIONS}</th>
                                        <th className={`${SINGLE_TEST_HEAD2}`}>{messages.NO_OF_ANSWERS_FOR_QUESTION}</th>
                                        <th className={`${SINGLE_TEST_HEAD2}`}>{messages.MAX_NO_OF_VA}</th>
                                        <th className={`${SINGLE_TEST_HEAD2}`}>{messages.ADMIT_Q_WITHOUT_VALID_ANSWER}</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr className="w-full border-collapse">
                                        <td className={`${SINGLE_TEST_BODY1}`}>
                                            <input
                                                type="text"
                                                value={testData.testName}
                                                onChange={handleChangeTestName}
                                                className="form-control w-full"/>
                                        </td>
                                        <td className={`${SINGLE_TEST_BODY2}`}>
                                            {testData.numberOfQuestions}
                                        </td>
                                        <td className={`${SINGLE_TEST_BODY2}`}>
                                            {testData.numberOfAnswersForQuestion}
                                        </td>
                                        <td className={`${SINGLE_TEST_BODY2}`}>
                                            {testData.maxNumberOfValidAnswers}
                                        </td>
                                        <td className={`${SINGLE_TEST_BODY2}`}>
                                            <div
                                                className="form-control flex items-center justify-center">
                                                {valueForAdmitQuestionsWithoutValidAnswer}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="relative h-screen overflow-hidden">
                            <div
                                className="absolute inset-0 overflow-y-auto mb-2">
                                <div>
                                    {showNetworkError && (
                                        <div className="mt-4">
                                            <MessageRed message={redMessage}/>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {
                                        // populateTable()
                                        (
                                            (testData.numberOfQuestions >= TEST_MIN_NO_OF_QUESTIONS)
                                            && (testData.numberOfQuestions <= TEST_MAX_NO_OF_QUESTIONS)
                                            && (testData.numberOfAnswersForQuestion >= QUESTION_MIN_NO_OF_ANSWERS)
                                            && (testData.numberOfAnswersForQuestion <= QUESTION_MAX_NO_OF_ANSWERS)
                                            && (testData.testName !== "")
                                            && (testData.maxNumberOfValidAnswers >= TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS)
                                            && (testData.maxNumberOfValidAnswers <= testData.numberOfAnswersForQuestion)
                                        ) ? (
                                            <div className=" w-full h-full table-auto mx-auto max-w-screen-3xl px-2 pb-2
                                                border-separate border-blue-400 border-4 rounded-lg
                                                bg-cyan-50">
                                                {
                                                    populateInner()
                                                }
                                            </div>
                                        ) : (
                                            redMessage = "No. of Questions [3 to 100] and No. of Answers [3 to 6]",
                                                showBadData = true
                                        )
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="mt-4">
                            {showBadData ? (
                                <div>
                                    <MessageRed message={redMessage}/>
                                </div>
                            ) : (
                                <div>
                                    {dataChecked ? (
                                        <div>
                                            <MessageGreen message={messages.TEST_UPDATED}/>
                                        </div>
                                    ) : (
                                        <div>
                                            <MessageGreen message={messages.UPDATE_THE_TEST_DATA}/>
                                        </div>
                                    )
                                    }
                                </div>
                            )}

                            <Button
                                size="lg" fullWidth
                                className={`${ACTION_BUTTON}`}
                                onClick={updateTest}
                            >
                                {messages.UPDATE_TEST}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
