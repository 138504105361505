import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
    ALL_TESTS_BODY1,
    ALL_TESTS_BODY2,
    ALL_TESTS_HEAD1,
    ALL_TESTS_HEAD2,
    AllTestsInfo_EN,
    AllTestsInfo_RO,
    LOCAL_URL
} from "../constants/constants"
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import MessageRed from "./MessageRed";
import ExportConfirmationDialog from "./ExportConfirmationDialog";
import {checkLogin} from "./Util";
import {useLanguage} from "../LanguageContext";

export default function AllTestsInfo() {

    const [data, setData] = useState([]);
    const url = `${LOCAL_URL}/api/tests`;
    const href = '/tests'
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showExportConfirmation, setShowExportConfirmation] = useState(false);
    const [test_Id, setTest_Id] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [position, setPosition] = useState({x: 0, y: 0});
    const [message, setMessage] = useState("")
    const [redMessage, setRedMessage] = useState("")
    let [showBadData, setShowBadData] = useState(false)


    const [messages, setMessages] = useState(AllTestsInfo_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(AllTestsInfo_EN);
        } else if (language === 'ro') {
            setMessages(AllTestsInfo_RO);
        }
    }, [language])


    useEffect(() => {
        checkLogin();    //const loggedUserId = checkLogin();
    }, []);

    useEffect(() => {
        axios.get(url)
            .then(response => {
                if (response.data.length === 0) {
                    setRedMessage(messages.TESTS_NOT_FOUND)
                    setShowBadData(true);
                } else {
                    const sortedData = response.data.sort((a, b) => a.testName.localeCompare(b.testName));
                    setData(sortedData);
                }
            })
            .catch(error => {
                console.error(messages.ERROR_FETCHING_DATA, error);
                setRedMessage(messages.ERROR_FETCHING_DATA + error.message);
                setShowBadData(true);
            });
    }, [messages.ERROR_FETCHING_DATA, messages.TESTS_NOT_FOUND, url]);


    const handleMouseEnter = (e, messageType) => {

        switch (messageType) {
            case "updateButton":
                setMessage(messages.UPDATE_TEST);
                break;
            case "deleteButton":
                setMessage(messages.DELETE_TEST);
                break;
            case "downloadButton":
                setMessage(messages.DOWNLOAD_CSV);
                break;
            case 'selectTest':
                setMessage(messages.TEST_DETAILS);
                break;
            default:
                setMessage("");
        }

        setIsHovered(true);

        setTimeout(() => {
            setShowMessage(true);
        }, 1000); // Set the delay in milliseconds (1 second in this example)
    }

    const handleMouseLeave = () => {
        setIsHovered(false);
        setShowMessage(false);
    };

    const handleMouseMove = (e) => {
        if (isHovered) {
            setPosition({x: e.clientX + 15, y: e.clientY + 15}); // Adjust the offset as needed
        }
    };

    async function handleButtonTestName(id) {
        window.location.href = `/tests/${id}`;
    }


    const handleDeleteClick = (id) => {
        // Display the confirmation dialog
        setShowDeleteConfirmation(true);
        setTest_Id(id);
    };


    const handleButtonEdit = (id) => {
        window.location.href = `/tests/${id}/update`;
    };

    const handleButtonExport = (id) => {
        setShowExportConfirmation(true);
        setTest_Id(id);
    };


    //TODO: vertical card for mobile
    return (<div className="mt-18">
        <table
            className="table-auto mt-4 mx-auto max-w-screen-3xl px-2 py-2 border-separate
                    border-blue-400 border-4 rounded-lg bg-cyan-50">
            <thead>
            <tr className="w-full border-collapse bg-cyan-100 key={test.testId}">
                <th className={`${ALL_TESTS_HEAD1} w-9/12`}>{messages.TEST_NAME}</th>
                <th className={`${ALL_TESTS_HEAD2}`}>{messages.NO_OF_QUESTIONS}</th>
                <th className={`${ALL_TESTS_HEAD2}`}>{messages.NO_OF_ANSWERS_FOR_QUESTION}</th>
                <th className={`${ALL_TESTS_HEAD2}`}>{messages.MAX_NO_VALID_ANSWERS}</th>
                <th className={`${ALL_TESTS_HEAD2}`}>{messages.ADMIT_Q_WITHOUT_VALID_ANSWER}</th>
            </tr>
            </thead>
            <tbody>
            {data.map((test, index) => (
                <tr className={`w-full border-collapse" ${index % 2 === 0 ? 'bg-yellow-50' : 'bg-cyan-50'}`}
                    key={index}>
                    <td className={`${ALL_TESTS_BODY1} relative`}>
                        <div>
                            <button
                                className="w-9/12 h-full p-0 text-left "
                                onClick={() => handleButtonTestName(test.testId)}
                                onMouseEnter={(e) => handleMouseEnter(e, "selectTest")}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}
                            >
                                {test.testName}
                            </button>
                        </div>
                        <div className="absolute right-1 top-1/2 transform -translate-y-1/2 space-x-2">

                            <button
                                className="hidden lg:inline text-right bg-blue-500 text-white px-1 py-1 rounded"
                                onClick={() => handleButtonEdit(test.testId)}
                                onMouseEnter={(e) => handleMouseEnter(e, "updateButton")}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                </svg>
                            </button>

                            <button
                                className="hidden lg:inline text-right bg-green-500 text-white px-1 py-1 rounded"
                                onClick={() => handleButtonExport(test.testId)}
                                onMouseEnter={(e) => handleMouseEnter(e, "downloadButton")}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm5.845 17.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V12a.75.75 0 0 0-1.5 0v4.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z"/>
                                </svg>
                            </button>

                            <button
                                className="hidden lg:inline text-right bg-red-500 text-white px-1 py-1 rounded"
                                onClick={() => handleDeleteClick(test.testId)}
                                onMouseEnter={(e) => handleMouseEnter(e, "deleteButton")}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                                </svg>

                            </button>
                        </div>
                    </td>
                    <td className={`${ALL_TESTS_BODY2}`}>{test.numberOfQuestions}</td>
                    <td className={`${ALL_TESTS_BODY2}`}>{test.numberOfAnswersForQuestion}</td>
                    <td className={`${ALL_TESTS_BODY2}`}>{test.maxNumberOfValidAnswers}</td>
                    <td className={`${ALL_TESTS_BODY2}`}>{test.admitQuestionsWithoutValidAnswer ? messages.YES : messages.NO}</td>
                </tr>))}
            </tbody>
        </table>

        <div className="mt-4">
            {showBadData ? (<div>
                <MessageRed message={redMessage}/>
            </div>) : (<div></div>)}
        </div>

        {showDeleteConfirmation && <DeleteConfirmationDialog
            setShowConf={setShowDeleteConfirmation}
            id={test_Id}
            href={href}
        />}

        {isHovered && showMessage && (
            <div className="absolute bg-orange-50 border-2 border-deep-purple-400 text-deep-purple-700 p-2 rounded-md"
                 style={{
                     position: 'fixed'
                     , left: `${position.x}px`
                     , top: `${position.y}px`
                 }}>
                {message}
            </div>
        )}

        {showExportConfirmation &&
            <ExportConfirmationDialog
                setShowConf={setShowExportConfirmation}
                id={test_Id}
                href={href}
            />
        }
    </div>);
}
