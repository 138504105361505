import React, {useEffect, useState} from 'react';
import axios from "axios";
import {DeleteConfirmationDialog_EN, DeleteConfirmationDialog_RO, LOCAL_URL} from "../constants/constants";
import {checkLogin} from "./Util";
import {useLanguage} from "../LanguageContext";

function DeleteConfirmationDialog({setShowConf, id, href}) {


    let [redMessage, setRedMessage] = useState("")
    let [showBadData, setShowBadData] = useState(false);

    const [messages, setMessages] = useState(DeleteConfirmationDialog_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(DeleteConfirmationDialog_EN);
        } else if (language === 'ro') {
            setMessages(DeleteConfirmationDialog_RO);
        }
    }, [language])


    useEffect(() => {
        checkLogin();    //const loggedUserId = checkLogin();
    }, []);

    async function handleConfirmDelete() {
        console.log(`test ID: ${id}`);
        await axios.delete(`${LOCAL_URL}/api/tests/${id}`)
            .then(function (response) {
                // Handle success, e.g., update UI or perform other tasks
                window.location.href = href;
            })
            .catch(function (error) {
                // Handle error, e.g., show an error message to the user
                console.error(messages.ERROR_DELETEING_RESOURCE, error);
                setRedMessage(messages.ERROR_DELETEING_RESOURCE + error.message);
                setShowBadData(true);
            });
    }


    function handleCancelDelete() {
        // Close the confirmation dialog without performing deletion
        setShowConf(false);
    }


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white border-separate border-red-500 border-4 shadow-md rounded-lg p-4">
                <p className="font-bold">
                    {messages.ARE_YOU_SURE}
                </p>
                <div className="mt-4 flex justify-center">
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2"
                        onClick={handleConfirmDelete}>
                        {messages.CONFIRM}
                    </button>
                    <button
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
                        onClick={handleCancelDelete}>
                        {messages.CANCEL}
                    </button>
                </div>
                <div className="mt-4">
                    {showBadData && (
                        <div className="bg-red-50 border-separate border-red-500 border-4 shadow-md rounded-lg p-4">
                            {redMessage}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DeleteConfirmationDialog;
