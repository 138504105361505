import React, {useEffect, useState} from "react";
import {
    ExportTestPage_EN, ExportTestPage_RO,
    ALL_TESTS_BODY2,
    ALL_TESTS_BODY4,
    ALL_TESTS_HEAD1,
    ALL_TESTS_HEAD2,
    LOCAL_URL
} from "../constants/constants";
import axios from "axios";
import MessageRed from "../components/MessageRed";
import ExportConfirmationDialog from "../components/ExportConfirmationDialog";
import {checkLogin} from "../components/Util";
import {useLanguage} from "../LanguageContext";


export default function ExportTestPage() {

    const [data, setData] = useState([]);

    const url = `${LOCAL_URL}/api/tests/user`;

    const href = `/tests/csv/export`;

    const [showConfirmation, setShowConfirmation] = useState(false);

    const [test_Id, setTest_Id] = useState(null);

    let [redMessage, setRedMessage] = useState("")
    let [showBadData, setShowBadData] = useState(false);

    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({x: 0, y: 0});

    const loggedUserId = checkLogin();

    const [messages, setMessages] = useState(ExportTestPage_EN);
    const {language} = useLanguage();

    useEffect(() => {
        if (language === 'en') {
            setMessages(ExportTestPage_EN);
        } else if (language === 'ro') {
            setMessages(ExportTestPage_RO);
        }
    }, [language])



    const handleExportClick = (id) => {
        // Display the confirmation dialog
        setShowConfirmation(true);
        setTest_Id(id);
    };


    useEffect(() => {
        axios.get(url + `/${loggedUserId}`)
            .then(response => {
                if (response.data.length === 0) {
                    setRedMessage(messages.TESTS_NOT_FOUND)
                    setShowBadData(true);
                } else {
                    const sortedData = response.data.sort((a, b) => a.testName.localeCompare(b.testName));
                    setData(sortedData);
                }
            })
            .catch(error => {
                console.error(messages.ERROR_FETCHING_DATA, error);
                setRedMessage(messages.ERROR_FETCHING_DATA + error.message);
                setShowBadData(true);
            });
    }, [loggedUserId, messages.ERROR_FETCHING_DATA, messages.TESTS_NOT_FOUND, url]);


    const handleMouseEnter = (e, messageType) => {

        switch (messageType) {
            case "updateButton":
                setMessage(messages.UPDATE_TEST);
                break;
            case "deleteButton":
                setMessage(messages.DELETE_TEST);
                break;
            case "downloadButton":
                setMessage(messages.DOWNLOAD_CSV);
                break;
            case 'selectTest':
                setMessage(messages.TEST_DETAILS);
                break;
            default:
                setMessage("");
        }

        setIsHovered(true);

        setTimeout(() => {
            setShowMessage(true);
        }, 1000); // Set the delay in milliseconds (1 second in this example)
    }


    function handleMouseLeave() {
        setShowMessage(false);
    }


    function handleMouseMove(e) {

        if (isHovered) {
            setPosition({x: e.pageX + 15, y: e.pageY + 15})
        }

    }


    return (
        <div className="mt-18">
            <table
                className="table-auto mx-auto max-w-screen-3xl px-2 py-2 border-separate border-blue-400 border-4 rounded-lg bg-cyan-50">
                <thead>
                <tr className="w-full border-collapse bg-green-100">
                    <th className={`${ALL_TESTS_HEAD1} w-9/12`}>{messages.TEST_NAME}</th>
                    <th className={`${ALL_TESTS_HEAD2}`}>{messages.NO_OF_QUESTIONS}</th>
                    <th className={`${ALL_TESTS_HEAD2}`}>{messages.NO_OF_ANSWERS_FOR_QUESTION}</th>
                    <th className={`${ALL_TESTS_HEAD2}`}>{messages.MAX_NO_VALID_ANSWERS}</th>
                    <th className={`${ALL_TESTS_HEAD2}`}>{messages.ADMIT_Q_WITHOUT_VALID_ANSWER}</th>
                </tr>
                </thead>
                <tbody>
                {data.map((test, index) => (
                    <tr className={`w-full border-collapse" ${index % 2 === 0 ? 'bg-yellow-50' : 'bg-cyan-50'}`}
                        key={index}>
                        <td className={`${ALL_TESTS_BODY4}`}>
                            <button
                                className="w-full h-full p-0 text-left "
                                onClick={() => handleExportClick(test.testId)}
                                onMouseEnter={(e) => handleMouseEnter(e, "downloadButton")}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}
                            >
                                {test.testName}
                            </button>
                        </td>
                        <td className={`${ALL_TESTS_BODY2}`}>{test.numberOfQuestions}</td>
                        <td className={`${ALL_TESTS_BODY2}`}>{test.numberOfAnswersForQuestion}</td>
                        <td className={`${ALL_TESTS_BODY2}`}>{test.maxNumberOfValidAnswers}</td>
                        <td className={`${ALL_TESTS_BODY2}`}>{test.admitQuestionsWithoutValidAnswer ? messages.YES : messages.NO}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            {isHovered && showMessage && (
                <div className="absolute bg-orange-50 border-2 border-deep-purple-400 text-deep-purple-700 p-2 rounded-md"
                     style={{
                         position: 'fixed'
                         , left: `${position.x}px`
                         , top: `${position.y}px`
                     }}>
                    {message}
                </div>
            )}

            {showConfirmation &&
                <ExportConfirmationDialog
                    setShowConf={setShowConfirmation}
                    id={test_Id}
                    href={href}
                />
            }

            <div className="mt-4">
                {showBadData && (
                    <div>
                        <MessageRed message={redMessage}/>
                    </div>
                )}
            </div>

        </div>
    );
}
