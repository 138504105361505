import React from "react";


export default function MessageGreen(message) {
    return (
        <div className="flex items-center justify-center text-center text-xl text-green-600 bg-green-50 h-full
            rounded-md mx-auto w-full">
            <h2 className="font-bold">{message.message}</h2>
        </div>
    )
}
