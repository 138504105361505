import React from "react";
import SingleTestData from "../components/SingleTestData";

export default function SingleTestPage() {

    return (
        <div className="single_test">
            <SingleTestData/>
        </div>
    )
}
