import React from "react";
import AllTestsInfo from "../components/AllTestsInfo";

export default function AllTestsPage() {

    return (
        <div className="all_tests">
            < AllTestsInfo/>
        </div>
    )
}
