// export const LOCAL_URL = `http://localhost:8080`;
// export const LOCAL_URL = `https://tstnow.ovh`; //OVHcloud - Canada
// export const LOCAL_URL = `http://51.89.23.5:8080`; //OVHcloud - Canada
// export const LOCAL_URL = `https://www.tstnow.ovh`; //OVHcloud - Canada


let LOCAL_URL;
if (process.env.NODE_ENV === 'production') {
    LOCAL_URL = 'https://tstnow.ovh';
} else if (process.env.NODE_ENV === 'production-test'){
    LOCAL_URL = 'https://testing.tstnow.ovh';
} else {
    LOCAL_URL = 'http://localhost:8080';
}
export {LOCAL_URL};


export const ALL_TESTS_HEAD1 = "w-1/4 border border-blue-400 rounded-md px-2 py-2";
export const ALL_TESTS_HEAD2 = "w-1/12 border border-blue-400 rounded-md px-2 py-2";
export const ALL_TESTS_BODY1 = "w-1/4 border border-blue-400 rounded-md px-2 py-2 hover:bg-blue-100";
export const ALL_TESTS_BODY2 = "w-1/12 border border-blue-400 rounded-md px-2 py-2 text-center align-middle";
export const ALL_TESTS_BODY3 = "w-1/4 border border-blue-400 rounded-md px-2 py-2 hover:bg-red-100";
export const ALL_TESTS_BODY4 = "w-1/4 border border-blue-400 rounded-md px-2 py-2 hover:bg-orange-100";


export const SINGLE_TEST_HEAD1 = "w-1/4 border border-blue-400 rounded-md px-2 py-2 text-left align-middle";
export const SINGLE_TEST_HEAD2 = ALL_TESTS_BODY2;
export const SINGLE_TEST_BODY1 = ALL_TESTS_HEAD1;
export const SINGLE_TEST_BODY2 = ALL_TESTS_BODY2;


export const TEST_CONTENT_QUESTION_HEAD1 = ALL_TESTS_HEAD2;
export const TEST_CONTENT_QUESTION_HEAD2 = "w-11/12 border border-blue-400 rounded-md px-2 py-2 text-left";
export const TEST_CONTENT_QUESTION_BODY1 = ALL_TESTS_BODY2;
export const TEST_CONTENT_QUESTION_BODY2 = "w-11/12 border border-blue-400 rounded-md px-2 py-2 text-left align-middle";


export const TEST_CONTENT_ANSWER_HEAD1 = ALL_TESTS_HEAD2;
export const TEST_CONTENT_ANSWER_HEAD2 = "w-10/12 border border-blue-400 rounded-md px-2 py-2 text-left";
export const TEST_CONTENT_ANSWER_BODY1 = ALL_TESTS_BODY2;
export const TEST_CONTENT_ANSWER_BODY2 = TEST_CONTENT_ANSWER_HEAD2;

export const ACTION_BUTTON = "basis-1/4 mt-4 bg-blue-500 hover:bg-orange-700 text-xl text-white font-bold";


export const COLORS = {
    blue: "bg-blue-50 text-blue-500",
    orange: "bg-orange-50 text-orange-500",
    green: "bg-green-50 text-green-500",
    gray: "bg-blue-gray-50 text-blue-gray-500",
    purple: "bg-purple-50 text-purple-500",
    teal: "bg-teal-50 text-teal-500",
    cyan: "bg-cyan-50 text-cyan-500",
    pink: "bg-pink-50 text-pink-500",
    red: "bg-red-50 text-red-500",
};


export const TEST_MIN_NO_OF_QUESTIONS = 3
export const TEST_MAX_NO_OF_QUESTIONS = 100
export const QUESTION_MIN_NO_OF_ANSWERS = 3
export const QUESTION_MAX_NO_OF_ANSWERS = 6
export const TEST_WITH_OPTION_NO_VALID_ANSWERS_QUESTION_MIN_NO_OF_VALID_ANSWERS = 0
export const QUESTION_MIN_NO_OF_VALID_ANSWERS = 1

//TEXTS

export const UserPage_EN = {
    USER_GREETING: "Hi ",
    SUBTITLE_1: "The data associated with your account",
    SUBTITLE_2: "Update your login data",
    NAME: "Name",
    SURNAME: "Surname",
    EMAIL: "Email",
    USER_ROLE: "User role",
    TESTS_OWNER: "Tests owner",
    ADMINISTRATOR: "Administrator",
    CANDIDATE_FOR_TESTING: "Candidate for testing",
    ERROR_GETTING_LOGGED_CLIENT_DATA: "Error getting logged client data: ",
    ERROR_PATCHING_NEW_PASSWORD: "Error saving new password, check current password ",
    CHANGE_PASSWORD: "Change Password",
    SAVE_NEW_PASSWORD: "Save new password",
    PASSWORD_TOO_SMALL: "Min length is 5 for ",
    PASSWORD_LENGTH_OK: "Valid password ",
    LOGOUT_BUTTON: "Logout",
    CURRENT_PASSWORD: "Current Password",
    NEW_PASSWORD: "New Password",
    PASSWORD_UPDATED: 'Password updated successfully',
}
export const UserPage_RO = {
    USER_GREETING: "Bună ",
    SUBTITLE_1: "Datele asociate cu contul tău",
    SUBTITLE_2: "Actualizați-vă datele de conectare",
    NAME: "Nume",
    SURNAME: "Prenume",
    EMAIL: "Email",
    USER_ROLE: "Rol utilizator",
    TESTS_OWNER: "Propietarul testelor",
    ADMINISTRATOR: "Administrator",
    CANDIDATE_FOR_TESTING: "Participant la testare",
    ERROR_GETTING_LOGGED_CLIENT_DATA: "Eroare la obținerea datelor client înregistrate:",
    ERROR_PATCHING_NEW_PASSWORD: "Eroare la salvarea parolei noi, verifică parola curentă ",
    CHANGE_PASSWORD: "Schimbă parola",
    SAVE_NEW_PASSWORD: "Salvează parola nouă",
    PASSWORD_TOO_SMALL: "Minim 5 caractere pentru ",
    PASSWORD_LENGTH_OK: "Parola valida ",
    LOGOUT_BUTTON: "Deconectare",
    CURRENT_PASSWORD: "Parolă actuală",
    NEW_PASSWORD: "Parolă nouă",
    PASSWORD_UPDATED: "Parola a fost actualizată cu succes.",
}


export const TimeToLogIn_EN = {
    RED_MESSAGE: "Session expired, it's time to log in",
}
export const TimeToLogIn_RO = {
    RED_MESSAGE: "Sesiune expirată, este timpul să vă autentificați.",
}


export const AddNewTest_EN = {
    COMPLETE_ALL_DATA: "Please complete all test structure data",
    NO_OF_QUESTIONS_NOT_SMALLER_THAN: "Number of questions must not be smaller than ",
    NO_OF_QUESTIONS_NOT_GREATER_THAN: "Number of questions must not be greater than ",
    N0_OF_ANSWERS_NOT_SMALLER_THAN: "Number of answers must not be smaller than ",
    N0_OF_ANSWERS_NOT_GREATER_THAN: "Number of answers must not be greater than ",
    UNABLE_TO_READ_TEST_DATA: "Unable to read testData.admitQuestionsWithoutValidAnswer",
    NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN: "Number of valid answers must not be smaller than ",
    NO_OF_VALID_ANSWERS_NOT_GREATER_THAN: "Number of valid answers must not be greater than ",
    ERROR_FETCHING_DATA: "Error fetching data: ",
    TO_MANY_VALID_ANSWERS_FOR_QUESTION: "To many valid answers for Question ",
    TEST_NAME_ALREADY_USED: `The test name was already used`,
    INPUT_ALL_DATA: "Please input all questions and all answers!",
    INVALID_NO_OF_RIGHT_ANSWERS: "Invalid number of right answers checks for question ",
    NO_OF_RIGHT_ANSWERS_BETWEEN_0_AND: "Number of right answers should be between 0 and ",
    NO_OF_RIGHT_ANSWERS_BETWEEN_1_AND: "Number of right answers should be between 1 and ",
    FOR_QUESTION: " for question ",
    THE_TEST_NAME_IS_MISSING: "The test name is missing",
    NO_OF_Q_NOT_BTW: "Number of questions not between ",
    AND: " and ",
    NO_OF_A_NOT_BTW: "Number of answers for a question not between ",
    NO_OF_VALID_A_NOT_BTW: "Number of valid answers for a question not between ",

    ERROR_WRITING_TEST_TO_DB: "Error writing test to the database: ",
    ERROR_WRITING_QUESTION_TO_DB: "Error writing question to the database: ",
    DELETED_SUCCESSFULLY: "Deleted successfully",
    ERROR_DELETING_RESOURCE: "Error deleting resource: ",
    ERROR_WRITING_ANSWER_TO_DB: "Error writing answer to the database: ",

    FAIL_TO_ADD_IMPORTED_CSV_TEST: "Fail to add imported csv test",

    QUESTION_NO: "Question Number",
    QUESTION: "Question",
    ANSWER_NO: "Answer Number",
    ANSWER: "Answer",
    RIGHT_ANSWER: "Right Answer",

    IMPORT_TEST: "IMPORT TEST",
    YES: "Yes",
    NO: "No",
    CHECK_IMPORTED_TEST: "Check imported test and edit it if necessary",
    SAVE_IMPORTED_TEST: "Save Imported CSV Test",

    TEST_NAME: `Test Name`,
    NO_OF_QUESTIONS: "Number Of Questions",
    NO_OF_ANSWERS_FOR_QUESTION: "Number Of Answers For Question",
    MAX_NO_VALID_ANSWERS: "Max Number Of Valid Answers",
    ADMIT_Q_WITHOUT_VALID_ANSWER: "Admit Questions Without Valid Answer",

    NO_OF_Q_BTW_AND_NO_OF_A_BTW: "No. of Questions [3 to 100] and No. of Answers [3 to 6]",
    TEST_SAVED: "Test Saved",
    COMPLETE_TEST_DATA: "Complete the test data",
    SAVE_TEST: "Save Test",
}
export const AddNewTest_RO = {
    COMPLETE_ALL_DATA: "Vă rugăm să completați toate datele structurii testului",
    NO_OF_QUESTIONS_NOT_SMALLER_THAN: "Numărul de întrebări nu trebuie să fie mai mic decât ",
    NO_OF_QUESTIONS_NOT_GREATER_THAN: "Numărul de întrebări nu trebuie să fie mai mare decât ",
    N0_OF_ANSWERS_NOT_SMALLER_THAN: "Numărul de răspunsuri nu trebuie să fie mai mic decât ",
    N0_OF_ANSWERS_NOT_GREATER_THAN: "Numărul de răspunsuri nu trebuie să fie mai mare decât ",
    UNABLE_TO_READ_TEST_DATA: "Imposibil de citit datele testului: testData.admitQuestionsWithoutValidAnswer",
    NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN: "Numărul de răspunsuri valide nu trebuie să fie mai mic decât ",
    NO_OF_VALID_ANSWERS_NOT_GREATER_THAN: "Numărul de răspunsuri valide nu trebuie să fie mai mare decât ",
    ERROR_FETCHING_DATA: "Eroare la preluarea datelor: ",
    TO_MANY_VALID_ANSWERS_FOR_QUESTION: "Prea multe răspunsuri valide pentru întrebarea ",
    TEST_NAME_ALREADY_USED: "Numele testului este deja folosit",
    INPUT_ALL_DATA: "Te rugăm să completezi toate întrebările și toate răspunsurile!",
    INVALID_NO_OF_RIGHT_ANSWERS: "Numărul invalid de răspunsuri corecte pentru întrebarea ",
    NO_OF_RIGHT_ANSWERS_BETWEEN_0_AND: "Numărul de răspunsuri corecte ar trebui să fie între 0 și ",
    NO_OF_RIGHT_ANSWERS_BETWEEN_1_AND: "Numărul de răspunsuri corecte ar trebui să fie între 1 și ",
    FOR_QUESTION: " pentru întrebarea ",
    THE_TEST_NAME_IS_MISSING: "Numele testului lipsește",
    NO_OF_Q_NOT_BTW: "Numărul de întrebări nu este între ",
    AND: " și ",
    NO_OF_A_NOT_BTW: "Numărul de răspunsuri pentru o întrebare nu este între ",
    NO_OF_VALID_A_NOT_BTW: "Numărul de răspunsuri valide pentru o întrebare nu este între ",

    ERROR_WRITING_TEST_TO_DB: "Eroare la scrierea testului în baza de date: ",
    ERROR_WRITING_QUESTION_TO_DB: "Eroare la scrierea întrebării în baza de date: ",
    DELETED_SUCCESSFULLY: "Șters cu succes",
    ERROR_DELETING_RESOURCE: "Eroare la ștergerea resursei: ",
    ERROR_WRITING_ANSWER_TO_DB: "Eroare la scrierea răspunsului în baza de date: ",

    FAIL_TO_ADD_IMPORTED_CSV_TEST: "Eșec la adăugarea testului CSV importat",

    QUESTION_NO: "Număr întrebare",
    QUESTION: "Întrebare",
    ANSWER_NO: "Număr răspuns",
    ANSWER: "Răspuns",
    RIGHT_ANSWER: "Răspuns Corect",

    IMPORT_TEST: "IMPORT TEST",
    YES: "Da",
    NO: "Nu",
    CHECK_IMPORTED_TEST: "Verifică testul importat și editează-l dacă este necesar",
    SAVE_IMPORTED_TEST: "Salvează Testul CSV Importat",

    TEST_NAME: "Nume Test",
    NO_OF_QUESTIONS: "Număr de Întrebări",
    NO_OF_ANSWERS_FOR_QUESTION: "Număr de Răspunsuri pentru Întrebare",
    MAX_NO_VALID_ANSWERS: "Număr Maxim de Răspunsuri Valide",
    ADMIT_Q_WITHOUT_VALID_ANSWER: "Permite Întrebări fără Răspuns Valid",

    NO_OF_Q_BTW_AND_NO_OF_A_BTW: "Nr. de Întrebări [3 până la 100] și Nr. de Răspunsuri [3 până la 6]",
    TEST_SAVED: "Test Salvat",
    COMPLETE_TEST_DATA: "Completează datele testului",
    SAVE_TEST: "Salvează Testul"
}


export const Navbar_EN = {
    TSTNOW: "TstNow",
    EN: "EN",
    RO: "RO",
    LOG_IN: "Log in",
    JOIN: "Join",
    USER: "User",
}
export const Navbar_RO = {
    TSTNOW: "TstNow",
    EN: "EN",
    RO: "RO",
    LOG_IN: "Autentificare",
    JOIN: "Înscrie-te",
    USER: "Utilizator"
}


export const TeacherAccountMenu_EN = {
    TEACHER_ACCOUNT: "Teacher Account",
    SHOW_ALL_TESTS: "Show all tests",
    SHOW_ALL_TESTS_DESCR: "List of all your tests.",
    ADD_NEW_TEST: "Add new test",
    ADD_NEW_TEST_DESCR: "New test with parameters to be added.",
    EXPORT_TEST: "Export test",
    EXPORT_TEST_DESCR: "Export a test form in a csv file.",
    IMPORT_TEST: "Import test",
    IMPORT_TEST_DESCR: "Import a test from a csv file.",
    UPDATE_TEST: "Update test",
    UPDATE_TEST_DESCR: "Update or change a test.",
    DELETE_TEST: "Delete test",
    DELETE_TEST_DESCR: "Delete a test.",
}
export const TeacherAccountMenu_RO = {
    TEACHER_ACCOUNT: "Cont profesor",
    SHOW_ALL_TESTS: "Afișează toate testele",
    SHOW_ALL_TESTS_DESCR: "Listă cu toate testele tale.",
    ADD_NEW_TEST: "Adaugă test nou",
    ADD_NEW_TEST_DESCR: "Test nou cu parametri de adăugare.",
    EXPORT_TEST: "Exportă testul",
    EXPORT_TEST_DESCR: "Exportă un formular de test într-un fișier CSV.",
    IMPORT_TEST: "Importă testul",
    IMPORT_TEST_DESCR: "Importă un test dintr-un fișier CSV.",
    UPDATE_TEST: "Actualizează testul",
    UPDATE_TEST_DESCR: "Actualizează sau schimbă un test.",
    DELETE_TEST: "Șterge testul",
    DELETE_TEST_DESCR: "Șterge un test."
}


export const SiteResourcesMenu_EN = {
    SITE_RESOURCES: "Site Resources",
    ABOUT_US: "About us",
    ABOUT_US_DESCR: "Learn about our story and our mission statement.",
    PRESS: "Press",
    PRESS_DESCR: "News and writings, press releases, and resources",
    HIRING: "We're hiring!",
    HIRING_DESCR: "We are always looking for talented people. Join us!",
    LEGAL: "Legal",
    LEGAL_DESCR: "All the stuff that we dan from legal made us add.",
    PRODUCTS: "Products",
    PRODUCTS_DESCR: "Checkout our products that helps a startup running.",
    OPEN_SOURCE: "Open Source",
    OPEN_SOURCE_DESCR: "List of all our open-source projects, it's all free.",
}
export const SiteResourcesMenu_RO = {
    SITE_RESOURCES: "Resursele site-ului",
    ABOUT_US: "Despre noi",
    ABOUT_US_DESCR: "Află despre povestea noastră și declarația noastră de misiune.",
    PRESS: "Presă",
    PRESS_DESCR: "Știri și articole, comunicate de presă și resurse",
    HIRING: "Angajăm!",
    HIRING_DESCR: "Suntem mereu în căutare de oameni talentați. Alătură-te nouă!",
    LEGAL: "Juridic",
    LEGAL_DESCR: "Toate informațiile legale pe care trebuie să le adăugăm.",
    PRODUCTS: "Produse",
    PRODUCTS_DESCR: "Vezi produsele noastre care ajută la pornirea unei afaceri.",
    OPEN_SOURCE: "Sursă gratuită",
    OPEN_SOURCE_DESCR: "Listă cu toate proiectele noastre open-source, totul este gratuit."
}


export const ExamKeyButton_EN = {
    EXAM_KEY: "Exam Key",
    GET_TEST: "Get Test",
}
export const ExamKeyButton_RO = {
    EXAM_KEY: "Cheie examen",
    GET_TEST: "Test"
}


export const AllTestsInfo_EN = {
    TESTS_NOT_FOUND: "Tests not found, the list is empty",
    ERROR_FETCHING_DATA: "Error fetching data: ",

    UPDATE_TEST: "Update test",
    DELETE_TEST: "Delete test",
    DOWNLOAD_CSV: "Download CSV file",
    TEST_DETAILS: "Test details",

    TEST_NAME: "Test Name",
    NO_OF_QUESTIONS: "Number Of Questions",
    NO_OF_ANSWERS_FOR_QUESTION: "Number Of Answers For Question",
    MAX_NO_VALID_ANSWERS: "Max Number Of Valid Answers",
    ADMIT_Q_WITHOUT_VALID_ANSWER: "Admit Questions Without Valid Answer",

    YES: "Yes",
    NO: "No",

    TEST_WITHOUT_DATA: "Test without data",
}
export const AllTestsInfo_RO = {
    TESTS_NOT_FOUND: "Testele nu au fost găsite, lista este goală",
    ERROR_FETCHING_DATA: "Eroare la preluarea datelor: ",
    UPDATE_TEST: "Actualizare test",
    DELETE_TEST: "Ștergere test",
    DOWNLOAD_CSV: "Descărcare fișier CSV",
    TEST_DETAILS: "Detalii test",

    TEST_NAME: "Nume test",
    NO_OF_QUESTIONS: "Număr de întrebări",
    NO_OF_ANSWERS_FOR_QUESTION: "Număr de răspunsuri pentru întrebare",
    MAX_NO_VALID_ANSWERS: "Număr maxim de răspunsuri valide",
    ADMIT_Q_WITHOUT_VALID_ANSWER: "Admitere întrebări fără răspuns valid",

    YES: "Da",
    NO: "Nu",

    TEST_WITHOUT_DATA: "Test fără date",
}

export const AllTestsByUserIdPage_EN = AllTestsInfo_EN;
export const AllTestsByUserIdPage_RO = AllTestsInfo_RO;

export const ExportTestPage_EN = AllTestsInfo_EN;
export const ExportTestPage_RO = AllTestsInfo_RO;

export const DeleteTest_EN = AllTestsInfo_EN;
export const DeleteTest_RO = AllTestsInfo_RO;

export const UpdateTestPage_EN = AllTestsInfo_EN;
export const UpdateTestPage_RO = AllTestsInfo_RO;


export const CarouselTransition_EN = {
    TITLE: "«This project is created to support educators and students in any subject where grid tests prove beneficial.»",
    SUBTITLE: "Ease of use:",
    ONLINE_EXAMS: "- ONLINE EXAMS ",
    ONLINE_EXAMS_DESCR: "- Logging in is straightforward, and the platform possesses all the necessary functionalities\n" +
        "                        for students.",
    USER_FRIENDLY: "- USER-FRIENDLY ",
    USER_FRIENDLY_DESCR: "- TstNow provides you with\n" +
        "                        the complete capability and features to design exams tailored to your preferences.",
    SUITABLE_FOR_EVERY_LEARNER: "- SUITABLE FOR EVERY LEARNER ",
    SUITABLE_FOR_EVERY_LEARNER_DESCR: "- Tailor each grid test in order to enhance every student's unique requirements,\n" +
        "                        allowing their expertise to truly stand out.",
}
export const CarouselTransition_RO = {
    TITLE: "„Acest proiect este creat pentru a sprijini educatori și cursanți în orice materie în care teste grilă sunt benefice.”",
    SUBTITLE: "Ușor de utilizat:",
    ONLINE_EXAMS: "- EXAMENE ONLINE ",
    ONLINE_EXAMS_DESCR: "- Autentificarea este simplă, iar platforma deține toate funcționalitățile necesare\n" +
        " pentru participanții la teste.",
    USER_FRIENDLY: "- PRIETENOASĂ CU UTILIZATORUL ",
    USER_FRIENDLY_DESCR: "- TstNow vă oferă\n" +
        " capacitatea completă și caracteristicile necesare pentru a proiecta examene personalizate conform preferințelor dumneavoastră.",
    SUITABLE_FOR_EVERY_LEARNER: "- POTRIVIT PENTRU FIECARE CURSANT ",
    SUITABLE_FOR_EVERY_LEARNER_DESCR: "- Personalizați fiecare test grilă pentru a-l adapta la cerințele unice ale fiecărui cursant,\n" +
        " permițând cunoștințelor acestora să iasă în evidență cu adevărat.",
}


export const ImportTestPage_EN = {
    CLICK_THE_BUTTON: "Click the button in order to select and import a CSV file",
    CHOOSE_FILE: "Choose a CSV File",
}
export const ImportTestPage_RO = {
    CLICK_THE_BUTTON: "Apăsați butonul pentru a selecta și importa un fișier CSV",
    CHOOSE_FILE: "Alegeți un fișier CSV",
}


export const ExportConfirmationDialog_EN = {
    TESTS_NOT_FOUND: "Tests not found, the list is empty",
    ERROR_FETCHING_DATA: "Error fetching data: ",
    CSV_FILE_SAVED: 'CSV file saved',
    ERROR_IN_SAVING_DATA: 'Error in saving data to CSV file: ',
    ARE_YOU_SURE: "Are you sure you want to download this item?",
}
export const ExportConfirmationDialog_RO = {
    TESTS_NOT_FOUND: "Nu s-au găsit teste, lista este goală",
    ERROR_FETCHING_DATA: "Eroare la preluarea datelor: ",
    CSV_FILE_SAVED: 'Fișier CSV salvat',
    ERROR_IN_SAVING_DATA: 'Eroare la salvarea datelor în fișierul CSV: ',
    ARE_YOU_SURE: "Ești sigur că vrei să descarci acest element?"
}


export const ShowImportTestPage_EN = AddNewTest_EN;
export const ShowImportTestPage_RO = AddNewTest_RO;


export const SingleTestData_EN = AllTestsInfo_EN;
export const SingleTestData_RO = AllTestsInfo_RO;


export const TestContent_EN = {
    QUESTION_NO: "Question No.",
    QUESTION: "Question",
    ANSWER_NO: "Answer No.",
    ANSWER: "Answer",
    RIGHT_ANSWER: "Right Answer",
    YES: "Yes",
    NO: "No",
}
export const TestContent_RO = {
    QUESTION_NO: "Număr întrebare",
    QUESTION: "Întrebare",
    ANSWER_NO: "Număr răspuns",
    ANSWER: "Răspuns",
    RIGHT_ANSWER: "Răspuns Corect",
    YES: "Yes",
    NO: "No",
}


export const DeleteConfirmationDialog_EN = {
    ERROR_DELETEING_RESOURCE: 'Error deleting resource: ',
    ARE_YOU_SURE: "Are you sure you want to delete this item?",
    CONFIRM: "Confirm",
    CANCEL: "Cancel",
}
export const DeleteConfirmationDialog_RO = {
    ERROR_DELETEING_RESOURCE: 'Eroare la ștergerea resursei: ',
    ARE_YOU_SURE: "Ești sigur că vrei să ștergi acest element?",
    CONFIRM: "Confirmă",
    CANCEL: "Anulează",
}


export const UpdateTest_EN = {
    ERROR_FETCHING_DATA: 'Error fetching data: ',
    PLEASE_COMPLETE_ALL_DATA: "Please complete all test structure data",
    NO_OF_Q_MUST_NOT_BE_SMALLER: "Number of questions must not be smaller than ",
    NO_OF_Q_MUST_NOT_BE_GREATER: "Number of questions must not be greater than ",
    NO_OF_A_MUST_NOT_BE_SMALLER: "Number of answers must not be smaller than ",
    NO_OF_A_MUST_NOT_BE_GREATER: "Number of answers must not be greater than ",
    NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN: "Number of valid answers must not be smaller than ",
    NO_OF_VALID_ANSWERS_NOT_GREATER_THAN: "Number of valid answers must not be greater than ",
    THE_TEST_NAME_ALREADY_USED: "The test name was already used",
    USER_NOT_MATCH: "Edited test owner not match with the logged user",
    PLEASE_INPUT_ALL_DATA: "Please input all questions and all answers!",
    INVALID_NO_OF_RIGHT_ANSWERS: "Invalid number of right answers checks for question ",
    NO_OF_RIGHT_ANSWERS_BETWEEN_0_AND: "Number of right answers should be between 0 and ",
    FOR_QUESTION: " for question ",
    NO_OF_RA_SHOULD_BE_1: "The number of right answers should be 1 for question ",
    NO_OF_RA_SHOULD_BE_BTW_1_AND: "The number of right answers should be between 1 and ",
    ERROR_UPDATING_OBJECT: "Error updating object: ",

    QUESTION_NO: "Question No.",
    QUESTION: "Question",
    ANSWER_NO: "Answer No.",
    ANSWER: "Answer",
    RIGHT_ANSWER: "Right Answer",
    YES: "Yes",
    NO: "No",

    UPDATE_TEST: "UPDATE TEST",
    TEST_NAME: "Test Name",
    NO_OF_QUESTIONS: "Number Of Questions",
    NO_OF_ANSWERS_FOR_QUESTION: "Number Of Answers For Question",
    MAX_NO_OF_VA: "Max Number Of Valid Answers",
    ADMIT_Q_WITHOUT_VALID_ANSWER: "Admit Questions Without Valid Answer",
    TEST_UPDATED: "Test Updated",
    UPDATE_THE_TEST_DATA: "Update the test data",
}
export const UpdateTest_RO = {
    ERROR_FETCHING_DATA: 'Eroare la preluarea datelor: ',
    PLEASE_COMPLETE_ALL_DATA: "Te rog completează toate datele structurii testului",
    NO_OF_Q_MUST_NOT_BE_SMALLER: "Numărul de întrebări nu trebuie să fie mai mic decât ",
    NO_OF_Q_MUST_NOT_BE_GREATER: "Numărul de întrebări nu trebuie să fie mai mare decât ",
    NO_OF_A_MUST_NOT_BE_SMALLER: "Numărul de răspunsuri nu trebuie să fie mai mic decât ",
    NO_OF_A_MUST_NOT_BE_GREATER: "Numărul de răspunsuri nu trebuie să fie mai mare decât ",
    NO_OF_VALID_ANSWERS_NOT_SMALLER_THAN: "Numărul de răspunsuri valide nu trebuie să fie mai mic decât ",
    NO_OF_VALID_ANSWERS_NOT_GREATER_THAN: "Numărul de răspunsuri valide nu trebuie să fie mai mare decât ",
    THE_TEST_NAME_ALREADY_USED: "Numele testului este deja folosit",
    USER_NOT_MATCH: "Proprietarul testului editat nu corespunde cu utilizatorul autentificat",
    PLEASE_INPUT_ALL_DATA: "Te rog introdu toate întrebările și toate răspunsurile!",
    INVALID_NO_OF_RIGHT_ANSWERS: "Număr invalid de verificări pentru răspunsuri corecte pentru întrebarea ",
    NO_OF_RIGHT_ANSWERS_BETWEEN_0_AND: "Numărul de răspunsuri corecte ar trebui să fie între 0 și ",
    FOR_QUESTION: " pentru întrebarea ",
    NO_OF_RA_SHOULD_BE_1: "Numărul de răspunsuri corecte ar trebui să fie 1 pentru întrebarea ",
    NO_OF_RA_SHOULD_BE_BTW_1_AND: "Numărul de răspunsuri corecte ar trebui să fie între 1 și ",
    ERROR_UPDATING_OBJECT: "Eroare la actualizarea obiectului: ",

    QUESTION_NO: "Numărul întrebării",
    QUESTION: "Întrebare",
    ANSWER_NO: "Numărul răspunsului",
    ANSWER: "Răspuns",
    RIGHT_ANSWER: "Răspuns corect",
    YES: "Da",
    NO: "Nu",

    UPDATE_TEST: "ACTUALIZEAZĂ TESTUL",
    TEST_NAME: "Numele testului",
    NO_OF_QUESTIONS: "Număr de întrebări",
    NO_OF_ANSWERS_FOR_QUESTION: "Număr de răspunsuri pentru întrebare",
    MAX_NO_OF_VA: "Număr maxim de răspunsuri valide",
    ADMIT_Q_WITHOUT_VALID_ANSWER: "Admitere întrebări fără răspuns valid",
    TEST_UPDATED: "Test actualizat",
    UPDATE_THE_TEST_DATA: "Actualizează datele testului"
}


export const SignInPage_EN = {
    INCORRECT_EMAIL_OR_PASSWORD: "Incorrect email or password",
    ERROR_GETTING_DATA_FOR_LOGGED_USER: "Error getting data for logged user: ",
    HELLO_AGAIN: "Hello again!",
    WELCOME_BACK: "Welcome back",
    FORGOT_PASSWORD: "Forgot Password ?",
    DON_T_HAVE_AN_ACCOUNT: "Don't have an account yet?",
    LOG_IN: "Log in",
    PASSWORD: "Password",
    EMAIL_ADDRESS: "Email Address",


}
export const SignInPage_RO = {
    INCORRECT_EMAIL_OR_PASSWORD: "Adresă de email sau parolă incorectă",
    ERROR_GETTING_DATA_FOR_LOGGED_USER: "Eroare la obținerea datelor utilizatorului autentificat: ",
    HELLO_AGAIN: "Bună din nou!",
    WELCOME_BACK: "Bine ai revenit",
    FORGOT_PASSWORD: "Ai uitat parola?",
    DON_T_HAVE_AN_ACCOUNT: "Nu ai încă un cont?",
    LOG_IN: "Autentificare",
    PASSWORD: "Parolă",
    EMAIL_ADDRESS: "Adresă de email"
}


export const SignUpPage_EN = {
    ERROR_SAVING_USER_DATA: "Error saving user data: ",
    WRONG_EMAIL_FORMAT: "Wrong email format",
    WELCOME: "Welcome to TstNow",
    ALREADY_REGISTERED: "Already registered, try to log in",
    NO_EMAIL: "No email data?",
    PASSWORD_SMALLER_THAN: "Password length smaller than 5",
    PASSWORD_DON_T_MATCH: "Password don't match",
    PASSWORD_MATCH: "Password match",
    ERROR_RETRIEVING_DATA: "Error retrieving data for user email duplicates: ",
    NAME: "Name",
    SURNAME: "Surname",
    EMAIL: "Email",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm Password",
    CREATE_ACCOUNT: "Create Account",
    BY_SIGNING_UP: "By signing up, you agree to the",
    TERMS_OF_SERVICE: "Terms of Service",
    AND: "and",
    PRIVACY_POLICY: "Privacy Policy",
    ALREADY_HAVE_AN_ACCOUNT: "Already have an account?",
    LOG_IN: "Log in",
}
export const SignUpPage_RO = {
    ERROR_SAVING_USER_DATA: "Eroare la salvarea datelor utilizatorului: ",
    WRONG_EMAIL_FORMAT: "Formatul email-ului este incorect",
    WELCOME: "Bine ai venit la TstNow",
    ALREADY_REGISTERED: "Deja înregistrat, încearcă să te autentifici",
    NO_EMAIL: "Nicio adresă de email?",
    PASSWORD_SMALLER_THAN: "Parola este mai mică decât 5 caractere",
    PASSWORD_DON_T_MATCH: "Parolele nu se potrivesc",
    PASSWORD_MATCH: "Parolele se potrivesc",
    ERROR_RETRIEVING_DATA: "Eroare la recuperarea datelor pentru verificarea duplicatelor de email: ",
    NAME: "Nume",
    SURNAME: "Prenume",
    EMAIL: "Email",
    PASSWORD: "Parolă",
    CONFIRM_PASSWORD: "Confirmă Parola",
    CREATE_ACCOUNT: "Creează Cont",
    BY_SIGNING_UP: "Prin înregistrare, ești de acord cu",
    TERMS_OF_SERVICE: "Termenii Serviciului",
    AND: "și",
    PRIVACY_POLICY: "Politica de Confidențialitate",
    ALREADY_HAVE_AN_ACCOUNT: "Ai deja un cont?",
    LOG_IN: "Autentificare",
}
