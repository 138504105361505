import React from "react";
import {List} from "@material-tailwind/react";
import SiteResourcesMenu from "./SiteResourcesMenu";
import TeacherAccountMenu from "./TeacherAccountMenu";

export default function NavList() {

    let clientIsLogged = false;

    if (sessionStorage.getItem("userId") != null) {
        clientIsLogged = true;
    }


    return (
        <List className="w-full mt-4 mb-2 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1 ">
            <SiteResourcesMenu/>
            {clientIsLogged && <TeacherAccountMenu/>}
        </List>
    );
}
