
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a new context for managing language
const LanguageContext = createContext();

// Custom hook to use language context
export const useLanguage = () => useContext(LanguageContext);

// Language provider component
export const LanguageProvider = ({ children }) => {
    const initialLanguage = localStorage.getItem('selectedLanguage') || 'en';
    const [language, setLanguage] = useState(initialLanguage);

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem('selectedLanguage', newLanguage);
    };

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
